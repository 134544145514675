import { DateFormat } from '@yiluhub/ui-utilities';
import dayjs from 'dayjs';
import { useCurrentProduct } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { roundUpToNext15MinutesInLocalTime } from 'utils/dateUtils';

import routes from 'router/routes';

import { constructFlightNumberSessionStorage } from 'modules/lounges/utils/constructFlightNumberSessionStorage';
import { constructLoungeBacklinkProps } from 'modules/lounges/utils/constructLoungeBackLinkProps';
import { getProductDetails } from 'modules/lounges/utils/getProductDetails';

import { useLoungeProduct, useRedirectOnLoungeNotFound } from '../../../hooks';
import { getLoungeProductDetailsPageQueryParams } from '../../../utils/query-params';
import useLoungeProductDetailsProps from './useLoungeProductDetailsProps';
import useLoungeProductSummaryProps from './useLoungeProductSummaryProps';
import useTracking from './useTracking';

/**
 * Retrieve data for the lounge product details page
 */
export const useLoungeProductDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    constructFlightNumberSessionStorage(location);
  }, [location]);

  const loungeProductDetailsPageQueryParams = useMemo(() => {
    return getLoungeProductDetailsPageQueryParams(location);
  }, [location]);
  const { loungeId, searchResultID, entryDate, adults, children, infants, coordinates } =
    loungeProductDetailsPageQueryParams;

  const roundedUpDate = useMemo(
    () => entryDate && roundUpToNext15MinutesInLocalTime(entryDate),
    [entryDate],
  );

  const { loungeProduct } = useLoungeProduct(
    loungeId,
    {
      adults: adults,
      children: children,
      infants: infants,
    },
    roundedUpDate,
    coordinates,
  );
  const { currentProduct } = useCurrentProduct(searchResultID);
  useRedirectOnLoungeNotFound(loungeId, searchResultID);

  const currentLounge = currentProduct || loungeProduct;

  const isPageReady = Boolean(currentLounge);

  useTracking(isPageReady);

  const productDetails = getProductDetails(currentLounge);

  const loungeProductDetailsProps = useLoungeProductDetailsProps(productDetails);
  const loungeProductSummaryProps = useLoungeProductSummaryProps(currentLounge);

  const iata = loungeProductDetailsProps?.iata ?? undefined;
  const date = loungeProductDetailsProps?.date
    ? dayjs(loungeProductDetailsProps.date).format(DateFormat.SHORT_DATE_WITH_TIME)
    : undefined;

  const backLinkProps = useMemo(() => {
    return constructLoungeBacklinkProps(
      iata ? `${t('lounges.pdp.backlink.label')} ${iata.toUpperCase()}` : '',
      routes.LOUNGES_SEARCH_RESULTS,
      navigate,
      date,
      iata,
      loungeProductDetailsProps?.adults,
      loungeProductDetailsProps?.children,
      loungeProductDetailsProps?.infants,
    );
  }, [t, iata, date, navigate, loungeProductDetailsProps]);

  return { isPageReady, loungeProductDetailsProps, loungeProductSummaryProps, backLinkProps };
};
