import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { LegalContentType } from 'applicationConstants';
import React from 'react';

import { getCurrentLanguage } from 'utils/i18n';

import Privacy from './Privacy';
import Terms from './Terms';
import Tracking from './Tracking';
import LegalDe from './de';
import LegalEn from './en';
import './styles.scss';

interface ILegalComponents {
  [key: string]: React.FC;
}

interface ILegalDocs {
  [key: string]: ILegalComponents;
}

const LegalDocs: ILegalDocs = {
  en: LegalEn,
  de: LegalDe,
};

const getLegalComponent = (contentType: string): React.FC => {
  const currentLanguage = getCurrentLanguage();

  switch (contentType) {
    case LegalContentType.TRACKING:
      /** since tracking page will use Lokalise we don't need to hard-code both language versions in code */
      return Tracking;
    case LegalContentType.PRIVACY:
      return Privacy;
    case LegalContentType.TERMS:
      return Terms;
    case LegalContentType.IMPRINT:
      return LegalDocs[currentLanguage].Imprint;
  }
  return LegalDocs[currentLanguage].Tracking;
};

interface ILegal {
  contentType: string;
  title: string;
  showClose?: boolean;
  handleClose: () => void;
  handleCloseBackBtn: () => void;
  isVisible: boolean;
}

const Legal: React.FC<ILegal> = ({
  contentType,
  title,
  showClose,
  handleClose,
  handleCloseBackBtn,
  isVisible,
}) => {
  const LegalComponent = getLegalComponent(contentType);
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');

  return (
    <ElementsSDK.Modal
      className="tracking-preferences-modal"
      isVisible={isVisible}
      onClickOutside={handleClose}
      title={title}
      onClose={showClose ? handleCloseBackBtn : undefined}
      size={isDesktop ? ElementsSDK.ModalSize.EXTRA_LARGE : ElementsSDK.ModalSize.LARGE}
      isCSSAnimationEnabled
    >
      <LegalComponent />
    </ElementsSDK.Modal>
  );
};

export default Legal;
