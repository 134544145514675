import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { Airport } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluEnv, yiluTracking } from 'utils';

import routes from 'router/routes';

import { AirportTransferSearchFormProps } from '../../../components';
import { AirportTransferSearchInputPageQueryParams } from '../../../utils/query-params';

const envVariables = yiluEnv.getVariables();

const useAirportTransferSearchFormProps = (
  airportTransferSearchInputPageQueryParams: AirportTransferSearchInputPageQueryParams,
  stationAirports: Airport[],
) => {
  const navigate = useNavigate();
  const airportTransferSearchFormProps = useMemo(() => {
    const {
      location,
      direction,
      transferType,
      transferDate,
      transferTime,
      airportDetails,
      travellersCount,
    } = airportTransferSearchInputPageQueryParams;
    const _props: AirportTransferSearchFormProps = {
      searchInput: {
        location: location,
        direction: direction,
        transferType: transferType,
        transferDate: transferDate,
        transferTime: transferTime,
        airportDetails,
        travellersCount,
      },
      googleMapsAPIKey: envVariables.GOOGLE_MAPS_KEY!,
      stationAirports,
      onOpenDateTimeInput(e) {
        const isMobile = UiUtilities.getMediaMatch('--viewport-lte-m');
        if (!isMobile) {
          return;
        }

        requestAnimationFrame(() => {
          const target = e.target as HTMLElement;
          target.scrollIntoView({
            behavior: 'smooth',
          });
        });
      },
      onSubmit(data) {
        yiluTracking.sendGAEvent({
          event: 'sip_cta_clicked',
          category: 'airport-transfer-sip',
          label: 'airport_transfer_sip_cta_clicked',
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const locationCoordinates = (data.locationDetails.coordinates ||
          data.locationDetails.location) as ElementsSDK.Coordinates;
        const nextSearchInput = {
          location: `${locationCoordinates.latitude},${locationCoordinates.longitude}`,
          airportDetails: data.airportDetails,
          direction: data.direction,
          transferType: data.transferType,
          transferDate: data.transferDate,
          transferTime: data.transferTime,
          travellersCount: data.travellersCount,
          stationId:
            (data.locationDetails as any).id || airportTransferSearchInputPageQueryParams.stationId,
        };

        const searchResultsPageQueryParams = {
          ...airportTransferSearchInputPageQueryParams,
          ...nextSearchInput,
        };

        navigate({
          pathname: routes.AIRPORT_TRANSFER_SEARCH_RESULTS,
          search: paramConverters.getURLSearchQuery(searchResultsPageQueryParams),
        });
      },
      onError(error) {
        throw error;
      },
    };
    return _props;
  }, [airportTransferSearchInputPageQueryParams, navigate, stationAirports]);

  return airportTransferSearchFormProps;
};

export default useAirportTransferSearchFormProps;
