import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'utils/i18n';

import { BookingTerms } from 'components/BookingConfirmation/components';
import 'components/BookingConfirmation/styles.scss';

import { HolidayExtraServiceProvider } from 'modules/lounges/utils/constants';

import { BookingEmailSupport } from './BookingEmailSupport';
import { BookingFAQSupport } from './BookingFAQSupport';
import { LoungesBookingInfo } from './LoungesBookingInfo';
import { getFaqLink } from './utils';

type LoungeBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'LOUNGE'>;
};

export const LoungeBookingConfirmation: React.FC<LoungeBookingConfirmationProps> = ({
  booking,
}) => {
  const { t } = useTranslation();

  const isHolidayExtras = booking?.serviceProvider === HolidayExtraServiceProvider;

  const currentLanguage = getCurrentLanguage();
  const faqLink = getFaqLink(booking, currentLanguage);

  const bookingData = booking.product.data;

  return (
    <>
      {bookingData && (
        <>
          <ElementsSDK.Message
            className="yilu-payment-bcp-email-confirmation"
            type={ElementsSDK.MessageType.SUCCESS}
            icon={ElementsSDK.Icon.Email}
            content={t('bcp.lounges.emailConfirmation', {
              email: bookingData.customerDetails.email,
            })}
          />
          <LoungesBookingInfo booking={booking} />
          {booking.product.data.termsUrl && (
            <BookingTerms termsUrl={bookingData.termsUrl} opensNewtab={isHolidayExtras} />
          )}
          {faqLink && !isHolidayExtras && <BookingFAQSupport faqLink={faqLink} />}
          {isHolidayExtras && (
            <BookingEmailSupport supportEmailAddress="lounge-support@yiluhub.com" />
          )}
        </>
      )}
    </>
  );
};
