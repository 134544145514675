import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingTerms } from 'components/BookingConfirmation/components';
import 'components/BookingConfirmation/styles.scss';

import { ParkingBookingInfo } from './ParkingBookingInfo';
import './styles.scss';

type ParkingBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'PARKING'>;
};

export const ParkingBookingConfirmation: React.FC<ParkingBookingConfirmationProps> = ({
  booking,
}) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;

  const isParkVia =
    booking.serviceProvider === 'PARK_VIA' ||
    booking.serviceProvider === 'Park Via' ||
    booking.serviceProvider === 'ParkVia';

  const getEmailCheck = (parkingBooking: Booking<'COMPLETED', 'PARKING'>) => {
    let provider = '';

    if (isParkVia) {
      provider = 'ParkVia';
    } else {
      //The address from Park Aero will always be the Airport's name
      provider = parkingBooking.product.data.parkingDetails.location.address || 'POI';
    }

    return t('parking.bcp.successMessage', {
      email: parkingBooking.product.data.customerDetails.email,
      provider: provider,
    });
  };

  return (
    <>
      {bookingData && (
        <>
          <ElementsSDK.Message
            className="yilu-payment-bcp-success__message"
            type={ElementsSDK.MessageType.SUCCESS}
            content={getEmailCheck(booking)}
          />
          {isParkVia && (
            <ElementsSDK.Button
              className="yilu-payment-bcp-save-confirmation_button"
              Icon={ElementsSDK.Icon.LinkOut}
              iconPosition={ElementsSDK.ButtonIconPositions.RIGHT}
              onClick={() => {
                window.open(bookingData.bookingConfirmationUrl, '_blank');
              }}
            >
              <>{t('parking.bcp.view.voucher.label')}</>
            </ElementsSDK.Button>
          )}
          <ElementsSDK.Divider />
          <ParkingBookingInfo booking={booking} />
          <BookingTerms opensNewtab termsUrl={bookingData.parkingDetails.termsUrl} />
        </>
      )}
    </>
  );
};
