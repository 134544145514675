import { FC, useCallback, useEffect, useState } from 'react';

import { usePaymentInputContext } from 'context/PaymentInput';

import { useCreatePaymentRequestButtonBooking } from '../hooks/useCreatePaymentRequestButtonBooking';
import '../styles.scss';
import { StripePaymentMethodProps } from '../types';

export type PaymentRequestInitializerProps = StripePaymentMethodProps & {
  touchPointId: string;
  userId: string;
  bookingIntentId: string;
  paymentRequest: stripe.paymentRequest.StripePaymentRequest;
  setIsCreateBookingLoading: (isLoading: boolean) => void;
};

export const PaymentRequestInitializer: FC<PaymentRequestInitializerProps> = ({
  stripe,
  clientSecret,
  bookingIntentId,
  userId,
  touchPointId,
  onPayment,
  onError,
  paymentRequest,
  setIsCreateBookingLoading,
}) => {
  const [initialised, setInitialised] = useState<boolean>(false);
  const { isTermsAccepted, setPaymentMethodOnSubmit } = usePaymentInputContext();

  const { onPaymentRequestConfirm } = useCreatePaymentRequestButtonBooking({
    stripe,
    clientSecret: clientSecret ? clientSecret.client_secret : null,
    bookingIntentId,
    paymentProviderId: 'STRIPE',
    userId,
    onPaymentResults: onPayment,
    onError,
    setIsLoading: setIsCreateBookingLoading,
    touchPointId,
  });

  useEffect(() => {
    if (stripe && paymentRequest && !initialised) {
      try {
        paymentRequest.on('paymentmethod', onPaymentRequestConfirm);
        setInitialised(true);
      } catch (error) {
        onError(error as Error);
      }
    }
  }, [initialised, paymentRequest, stripe, onError, onPaymentRequestConfirm]);

  //on submit show payment request
  const handleFormSubmit = useCallback(() => {
    try {
      isTermsAccepted && paymentRequest.show();
    } catch (error) {
      onError(error as Error);
    }
  }, [isTermsAccepted, paymentRequest, onError]);

  //set payment method on submit to be called from parent
  useEffect(() => {
    setPaymentMethodOnSubmit(() => () => handleFormSubmit());
  }, [setPaymentMethodOnSubmit, handleFormSubmit]);

  return null;
};
