import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { Booking } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils';

import {
  BookingContactForm,
  BookingProviderInfo,
  BookingTerms,
} from 'components/BookingConfirmation/components';

import { AirportTransferBookingInfo } from './AirportTransferBookingInfo';
import './styles.scss';

type AirportTransferBookingConfirmationProps = {
  booking: Booking<'COMPLETED', 'BUS'>;
};

const INTERNAL_CONTENT_PREFIX_URL =
  'https://provider-resources-staging-v2.s3.eu-central-1.amazonaws.com/distribusion/terms';

export const AirportTransferBookingConfirmation: React.FC<
  AirportTransferBookingConfirmationProps
> = ({ booking }) => {
  const { t } = useTranslation();
  const envVariables = yiluEnv.getVariables();

  const bookingData = booking.product.data;

  return (
    <>
      {bookingData && (
        <>
          <div className="yilu-AirportTransferBookingConfirmation-container">
            <BookingProviderInfo
              className="yilu-AirportTransferBookingConfirmation-BookingProviderInfo"
              logoUrl={bookingData.busDetails.logoUrl}
            />
            {bookingData.ticketUrl && (
              <ElementsSDK.DownloadButton
                linkUrl={bookingData.ticketUrl}
                label={t('bcp.downloadPDF')}
              />
            )}
          </div>
          <ElementsSDK.Divider />
          <AirportTransferBookingInfo booking={booking} />
          <BookingTerms
            termsUrl={bookingData.termsUrl}
            opensNewtab={!bookingData.termsUrl?.startsWith(INTERNAL_CONTENT_PREFIX_URL)}
          />
          <BookingContactForm
            contactFormText={t('transfers.bcp.contact_form.text')}
            contactFormLink={t(
              `transfers.bcp.contact_form_${envVariables?.YILU_ENTERPRISE_PARTNER || 'LH'}`,
              {
                carrier: booking.enterprisePartner,
                bookingNumber: bookingData.bookingId,
              },
            )}
            contactFormLinkText={t('transfers.bcp.contact_form_link.text')}
          />
        </>
      )}
    </>
  );
};
