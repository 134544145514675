import { SearchItem } from '@yiluhub/yilu-amp-types';

import { extractAmpProductAttribute } from 'utils/ampUtils';

export type RidesBookingIntentResultCardData = {
  providerId: string;
  serviceProvider: string;
  providerName: string;
  departureDateTime: string;
  arrivalDateTime: string;
  destinationLatitude: number;
  destinationLongitude: number;
  price: number;
  currency: string;
  travellersCount: number;
  carrierName: string;
  carrierLogoUrl: string;
  providerClassification: string;
  termsUrl: string;
  originLatitude: string | number;
  originLongitude: string | number;
  priceId: string;
  catalogId: string;
  searchResultId: string;
};

export const useAmpRidesResultData = (
  data: SearchItem | undefined | null,
): RidesBookingIntentResultCardData => {
  if (!data) {
    return getDefaultValues();
  }

  const getPrice = (data: SearchItem) => {
    const product = data?.item?.priceOptions;
    return product && product[0].price;
  };

  const price = getPrice(data)?.amount;
  const currency = getPrice(data)?.currency || 'EUR';

  const ridesData = {
    providerId: data?.id,
    searchResultId: data?.id,
    serviceProvider: extractAmpProductAttribute(data, 'service_provider') || '',
    providerName:
      extractAmpProductAttribute(data, 'carrier_name') ||
      extractAmpProductAttribute(data, 'service_provider') ||
      '',
    departureDateTime: extractAmpProductAttribute(data, 'departure_time') || '',
    arrivalDateTime: extractAmpProductAttribute(data, 'arrival_time') || '',
    destinationLatitude: extractAmpProductAttribute(data, 'end_point_latitude') as any as number,
    destinationLongitude: extractAmpProductAttribute(data, 'end_point_longitude') as any as number,
    price: (price && price / 100) || 0,
    currency,
    travellersCount: extractAmpProductAttribute(data, 'number_of_passengers') as any as number,
    carrierName: extractAmpProductAttribute(data, 'carrier_name') || '',
    carrierLogoUrl: extractAmpProductAttribute(data, 'carrier_logo_url') || '',
    providerClassification: extractAmpProductAttribute(data, 'provider_classification') || '',
    termsUrl: extractAmpProductAttribute(data, 'terms_url') || '',
    originLatitude: extractAmpProductAttribute(data, 'start_point_latitude') || '',
    originLongitude: extractAmpProductAttribute(data, 'start_point_longitude') || '',
    priceId: data?.item?.defaultPrice?.id,
    catalogId: data?.item?.catalogId,
  };

  return ridesData;
};

const getDefaultValues = (): RidesBookingIntentResultCardData => {
  return {
    providerId: '',
    serviceProvider: '',
    providerName: '',
    departureDateTime: '',
    arrivalDateTime: '',
    destinationLatitude: 0,
    destinationLongitude: 0,
    price: 0,
    currency: '',
    travellersCount: 0,
    carrierName: '',
    carrierLogoUrl: '',
    providerClassification: '',
    termsUrl: '',
    originLatitude: 0,
    originLongitude: 0,
    priceId: '',
    catalogId: '',
    searchResultId: '',
  };
};
