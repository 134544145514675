import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { yiluEnv } from 'utils';
import { getURLSearchQuery } from 'utils/paramConverters';
import { sendGAEvent, sendPaymentSuccessfulEvent } from 'utils/tracking';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { RideInteractiveProductSummaryProps } from 'modules/rides/components/RideInteractiveProductSummary/types';
import { useAmpRidesResultData } from 'modules/rides/utils/useAmpRidesResultData';

import { getRidesPaymentInputPageQueryParams } from '../../utils/query-params';

const yiluEnvVariables = yiluEnv.getVariables();
/**
 * Retrieve data for the Rides PaymentInputPage.
 */
export const usePaymentInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const ridesPaymentInputPageQueryParams = useMemo(() => {
    return getRidesPaymentInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(ridesPaymentInputPageQueryParams.searchResultID);

  const paymentFormRef = useRef<HTMLFormElement>(null);
  const isPageReady = Boolean(currentProduct);
  const {
    providerName,
    price,
    currency,
    providerId,
    termsUrl,
    providerClassification,
    carrierLogoUrl,
    destinationLatitude,
    carrierName,
    travellersCount,
    destinationLongitude,
    originLatitude,
    originLongitude,
  } = useAmpRidesResultData(currentProduct);
  // Define PaymentInputForm Props
  const paymentInformationFormProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }
    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      amount: {
        price,
        currency,
      },
      stripeMerchantLabel: providerName || providerId,
      productTrackingId: 'rides',
      serviceProviderId: providerId,
      bookingIntentId: ridesPaymentInputPageQueryParams.bookingIntentId,
      userId: ridesPaymentInputPageQueryParams.userId as string,
      touchPointId: ridesPaymentInputPageQueryParams.touchpoint as string,
      shoppingCartId: ridesPaymentInputPageQueryParams.shoppingCartId,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        sendPaymentSuccessfulEvent(ridesPaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.RIDES_BCP,
          search: getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [
    currentProduct,
    ridesPaymentInputPageQueryParams,
    navigate,
    currency,
    providerId,
    price,
    providerName,
  ]);

  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    sendGAEvent({
      event: 'page_loaded',
      category: 'ride-pip',
      label: 'ride_pip_loaded',
    });
  }, [isPageReady]);

  const rideProductInteractiveSummaryProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const _props: RideInteractiveProductSummaryProps = {
      whereFrom: `${originLatitude},${originLongitude}`,
      whereTo: `${destinationLatitude},${destinationLongitude}`,
      travelDate: `${ridesPaymentInputPageQueryParams.transferDate}T${ridesPaymentInputPageQueryParams.transferTime}`,
      isEstimatedPriceActive: false,
      price,
      currency,
      travellersCount,
      carrierName,
      carrierLogoUrl,
      providerClassification,
      googleMapsAPIKey: yiluEnvVariables.GOOGLE_MAPS_KEY!,
      termsDetails: {
        termsUrl: termsUrl,
        productDetails: {
          providerId: providerId,
          providerName: providerName,
        },
      },
      onSubmit() {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          sendGAEvent({
            event: 'pip_cta_clicked',
            category: 'ride-pip',
            label: 'ride_pip_cta_clicked',
          });
        }
      },
      onSearch() {},
    };
    return _props;
  }, [
    currentProduct,
    paymentFormRef,
    ridesPaymentInputPageQueryParams,
    originLatitude,
    originLongitude,
    carrierLogoUrl,
    carrierName,
    currency,
    destinationLatitude,
    destinationLongitude,
    price,
    providerClassification,
    providerId,
    providerName,
    termsUrl,
    travellersCount,
  ]);

  return {
    isPageReady,
    paymentInformationFormProps,
    rideProductInteractiveSummaryProps,
  };
};
