/**
 * # Use Lounge Product
 * Store used in Lounges PDP when redirecting from QR codes.
 * ex. https://simpletrip.travelintegration.com/lounges/product?loungeId=10
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { useEffect } from 'react';

import { getCurrentLanguage } from 'utils/i18n';

import { useLoungeProductContext } from '../context';

/**
 * Use lounge product hook
 * Fetches lounge product data for PDP deeplinking and walk-ins
 *
 * @param loungeId - lounge product identifier
 * @param travellersCount
 * @param entryDate - date of entry
 * @param airportCoordinates
 */
export function useLoungeProduct(
  loungeId?: string,
  travellersCount?: {
    adults?: number;
    children?: number;
    infants?: number;
  },
  entryDate?: string,
  airportCoordinates?: ElementsSDK.Coordinates,
) {
  const {
    data: selectedLoungeProduct,
    loading: isLoungeProductLoading,
    error: loungeProductError,
    loadLoungeProduct,
  } = useLoungeProductContext();

  const selectedLoungeProductId = selectedLoungeProduct?.item.productId;
  const loungeProduct =
    selectedLoungeProduct && selectedLoungeProductId === loungeId
      ? selectedLoungeProduct
      : undefined;

  useEffect(() => {
    if (loungeProduct || !loungeId || isLoungeProductLoading) {
      return;
    }

    loadLoungeProduct(
      loungeId,
      getCurrentLanguage(),
      travellersCount,
      entryDate,
      airportCoordinates,
    );
  }, [
    loungeProduct,
    loungeId,
    loadLoungeProduct,
    travellersCount,
    entryDate,
    airportCoordinates,
    isLoungeProductLoading,
  ]);

  return {
    loungeProduct,
    isLoungeProductLoading,
    loungeProductError,
  };
}
