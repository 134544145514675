import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { GetProductsRequest, GetProductsResponse, SearchItem } from '@yiluhub/yilu-amp-types';
import { TransferDirection } from 'applicationConstants';
import axios from 'axios';
import { useEffect, useState } from 'react';
import spacetime from 'spacetime';
import tzlookup from 'tz-lookup';

import { ampUtils, yiluEnv } from 'utils';
import { getCurrentLanguage } from 'utils/i18n';

import { TransferType } from 'modules/airport-transfer/utils/constants';

const DEFAULT_PAGE_SIZE = 10;

export type UseAirportTransferSearchResultsLoaderParams = {
  searchParams: {
    passengerCount: number;
    direction: TransferDirection;
    iata: string;
    locationCoordinates: { latitude: number; longitude: number };
    transferType: TransferType;
    transferDate: string;
    transferTime: string;
    carrier?: string;
    stationId?: string;
  };
  onSearchResults?: (results: SearchItem[]) => unknown;
  onNoSearchResults?: () => unknown;
  onError?: (error: Error) => unknown;
  pageSize?: number;
};

export const useAirportTransferSearchResultsLoader = ({
  searchParams,
  onSearchResults,
  onError,
  pageSize = DEFAULT_PAGE_SIZE,
}: UseAirportTransferSearchResultsLoaderParams) => {
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshPagination, setRefreshPagination] = useState(false);
  const [searchID, setSearchID] = useState<string>();
  const { YILU_AMP_STORE_ID, YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID, YILU_AMP_BACKEND_URL } =
    yiluEnv.getVariables();

  const getRemoteTransferDateTime = (locationCoordinates: ElementsSDK.Coordinates) => {
    const dayjs = UiUtilities.getDayjs();
    const locationTimezone = tzlookup(locationCoordinates.latitude, locationCoordinates.longitude);
    const locationSpace = spacetime(searchParams.transferDate, locationTimezone);
    const remoteTransferEpoch = locationSpace.time(searchParams.transferTime).epoch;
    const remoteTransferDateTime = dayjs(remoteTransferEpoch).toISOString();
    return remoteTransferDateTime;
  };

  const filterDepartureOrArrivalHex = (searchResultItem: SearchItem) => {
    // regex to filter Terminal 5 and T5 as per (https://yiluts.atlassian.net/browse/YAF-3274)
    const filterTerminalFiveRegex = /(?:Terminal 5|T5)/;
    const originName = ampUtils.extractAmpProductAttribute(
      searchResultItem,
      'departure_station_name',
    );
    const destinationName = ampUtils.extractAmpProductAttribute(
      searchResultItem,
      'arrival_station_name',
    );

    return (
      !(originName && filterTerminalFiveRegex.test(originName)) &&
      !(destinationName && filterTerminalFiveRegex.test(destinationName))
    );
  };

  const fetchAirportTransfers = async () => {
    const locationCoordinates = searchParams.locationCoordinates;
    const transferDateTime = getRemoteTransferDateTime(locationCoordinates);
    let from =
      searchParams.direction === TransferDirection.FROM_AIRPORT
        ? searchParams.iata
        : (searchParams.stationId as string);

    if (!from && locationCoordinates && TransferDirection.TO_AIRPORT) {
      from = `${locationCoordinates.latitude},${locationCoordinates.longitude}`;
    }

    let to =
      searchParams.direction === TransferDirection.TO_AIRPORT
        ? searchParams.iata
        : (searchParams.stationId as string);

    if (!to && locationCoordinates && !searchParams?.stationId) {
      to = `${locationCoordinates.latitude},${locationCoordinates.longitude}`;
    }

    const airportTransferRequest: GetProductsRequest['query'] = {
      storeId: YILU_AMP_STORE_ID,
      verticalId: YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
      to,
      from,
      numberOfTravelers: searchParams.passengerCount,
      pageNumber,
      pageSize,
      searchID,
      locale: getCurrentLanguage(),
      currency: 'EUR',
    };

    if (searchParams.transferType === TransferType.DEPARTURE) {
      airportTransferRequest.startAt = transferDateTime.replace(/\.[0-9]{3}/, '');
    } else {
      airportTransferRequest.endAt = transferDateTime.replace(/\.[0-9]{3}/, '');
    }

    const response = await axios.get<GetProductsResponse>(
      `${YILU_AMP_BACKEND_URL}/product/v1/products`,
      {
        params: airportTransferRequest,
      },
    );
    const body = response.data;
    const newSearchResults =
      searchParams.carrier === 'HEXR'
        ? body.items.filter((searchResultItem) => filterDepartureOrArrivalHex(searchResultItem))
        : body.items;

    const responsePage = body.page;
    const totalPages = Math.ceil(responsePage.total / responsePage.pageSize);
    setTotalPages(totalPages);
    setSearchID(body.searchID);
    if (onSearchResults) {
      onSearchResults(newSearchResults);
    }
    setSearchResults([...searchResults, ...newSearchResults]);
  };

  const loadResults = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await fetchAirportTransfers();
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error as Error);
      }
      console.error('Error loading airport transfer search results', error);
    } finally {
      setIsLoading(false);
      setRefreshPagination(false);
    }
  };

  useEffect(() => {
    setSearchResults([]);
    setRefreshPagination(true);
    setSearchID(undefined);
  }, [searchParams]);

  const { setLastElement, pageNumber } = UiUtilities.useObserverPagination({
    loadResults,
    totalPages,
    refreshPagination,
    searchID,
  });

  return {
    searchResults,
    isLoading,
    totalPages,
    pageNumber,
    setLastElement,
  };
};
