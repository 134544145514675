import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeading } from 'components';
import BacklinkNavigator from 'components/Header/BacklinkNavigator';
import InteractiveSummaryLayout from 'components/Layout/InteractiveSummaryLayout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';

import { LoungeInteractiveProductSummary } from 'modules/lounges/components';

import { LoungeProductDetails } from './LoungeProductDetails';
import { useLoungeProductDetailsPage } from './hooks';
import './styles.scss';

const ProductDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isPageReady, loungeProductSummaryProps, loungeProductDetailsProps, backLinkProps } =
    useLoungeProductDetailsPage();
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');
  const showBacklinkNavigator = loungeProductSummaryProps
    ? loungeProductSummaryProps.showBacklinkNavigator
    : true;

  return (
    <>
      {showBacklinkNavigator && <BacklinkNavigator backLinkProps={backLinkProps} />}
      <div className="lounges-pdp">
        {isPageReady ? (
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <PageHeading text={t('Header - Details')} />
                {loungeProductSummaryProps && !isDesktop && (
                  <InteractiveSummaryLayout>
                    <LoungeInteractiveProductSummary {...loungeProductSummaryProps} />
                  </InteractiveSummaryLayout>
                )}
                {loungeProductDetailsProps && (
                  <LoungeProductDetails {...loungeProductDetailsProps} />
                )}
              </div>
              {loungeProductSummaryProps && isDesktop && (
                <InteractiveSummaryLayout>
                  <LoungeInteractiveProductSummary {...loungeProductSummaryProps} />
                </InteractiveSummaryLayout>
              )}
            </div>
          </div>
        ) : (
          <LoadingPageAnimation />
        )}
      </div>
    </>
  );
};

export default ProductDetailsPage;
