/**
 * # Airport Transfer Product Details
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { useRemoteContent } from './useRemoteContent';

const INTERNAL_CONTENT_PREFIX_URL =
  'https://provider-resources-staging-v2.s3.eu-central-1.amazonaws.com/distribusion/terms';

export type AirportTransferProductDetailsProps = Omit<ElementsSDK.ItineraryProps, 'icon'> & {
  carrierName: string;
  carrierLogoUrl: string;
  carrierLuggagePolicyUrl?: string;
};

export const AirportTransferProductDetails: React.FC<AirportTransferProductDetailsProps> = ({
  originDepartureTime,
  originAddress,
  originHighlight,
  originComment,
  transportComment,
  destinationArrivalTime,
  destinationAddress,
  destinationHighlight,
  destinationComment,
  displayingTimezone,
  carrierName,
  carrierLogoUrl,
  carrierLuggagePolicyUrl,
}) => {
  const { t } = useTranslation();
  const productDetailsInfos = [];

  if (carrierLogoUrl || carrierName) {
    productDetailsInfos.push(
      <div
        key="operator-logo"
        className="yilu-ProductDetails__AirportTransfer___OperatorLogoWrapper"
      >
        {carrierLogoUrl ? (
          <img
            className="yilu-ProductDetails__AirportTransfer__OperatorLogo"
            src={carrierLogoUrl}
            alt={t('Operator logo')}
          />
        ) : (
          <div className="yilu-ProductDetails__AirportTransfer__MajorOffset">
            <ElementsSDK.Typography variant="h4">{carrierName}</ElementsSDK.Typography>
          </div>
        )}
      </div>,
    );
  }

  if (productDetailsInfos.length) {
    productDetailsInfos.push(
      <span key="separator-1" className="yilu-ProductDetails__AirportTransfer__Separator" />,
    );
  }

  productDetailsInfos.push(
    <ElementsSDK.Itinerary
      key="itinerary"
      icon={<ElementsSDK.Icon.Bus />}
      originDepartureTime={originDepartureTime}
      originAddress={originAddress}
      originHighlight={originHighlight}
      originComment={originComment}
      transportComment={transportComment}
      destinationArrivalTime={destinationArrivalTime}
      destinationAddress={destinationAddress}
      destinationHighlight={destinationHighlight}
      destinationComment={destinationComment}
      displayingTimezone={displayingTimezone}
    />,
  );

  const productsDetailsAdditions = [];

  const {
    isVisible: isCarrierLuggagePolicyVisible,
    toggleVisibility: toggleCarrierLuggagePolicyVisibility,
    isPending: isCarrierLuggagePolicyPending,
    content: carrierLuggagePolicy,
  } = useRemoteContent(carrierLuggagePolicyUrl);

  const isInternalContent = carrierLuggagePolicyUrl?.startsWith(INTERNAL_CONTENT_PREFIX_URL);

  if (toggleCarrierLuggagePolicyVisibility) {
    productsDetailsAdditions.push(
      <div key="luggage-policy" className="yilu-ProductDetails__AirportTransfer__Box">
        <ElementsSDK.Icon.Info />
        <div className="yilu-ProductDetails__AirportTransfer__MajorOffset">
          <button
            className="yilu-ProductDetails__AirportTransfer__Interactive"
            onClick={
              isInternalContent
                ? toggleCarrierLuggagePolicyVisibility
                : () => window.open(carrierLuggagePolicyUrl, '_blank')
            }
          >
            {t('Luggage policy')}
          </button>
        </div>
        {isCarrierLuggagePolicyVisible && (
          <ElementsSDK.Drawer
            className="yilu-ProductDetails__AirportTransfer__Drawer"
            onClose={toggleCarrierLuggagePolicyVisibility}
          >
            <>
              <div>{isCarrierLuggagePolicyPending && t('Loading...')}</div>
              {carrierLuggagePolicy && (
                <div
                  className="yilu-ProductDetails__AirportTransfer___Policy"
                  dangerouslySetInnerHTML={{
                    __html: carrierLuggagePolicy,
                  }}
                />
              )}
            </>
          </ElementsSDK.Drawer>
        )}
      </div>,
    );
  }

  return (
    <div className="yilu-ProductDetails__AirportTransfer">
      {productDetailsInfos}
      {productsDetailsAdditions.length > 0 && (
        <div className="yilu-ProductDetails__AirportTransfer__Additions">
          {productsDetailsAdditions}
        </div>
      )}
    </div>
  );
};
