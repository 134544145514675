/**
 * # Rides Search Form
 *
 *
 */
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchInput } from './hooks/useSearchInput';
import './styles.scss';
import { RidesSearchFormProps } from './types';

const DEFAULT_MAX_TRAVELLERS_COUNT = 9;

export const RidesSearchForm: React.FC<RidesSearchFormProps> = ({
  className,
  onSubmit,
  googleMapsAPIKey,
  searchInput,
  onError,
  minDateTimeInput,
  maxTravellersCount = DEFAULT_MAX_TRAVELLERS_COUNT,
  onOpenDateTimeInput,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = UiUtilities.useIsDesktop();

  const {
    originAddressInputId,
    originAddress,
    destinationAddressInputId,
    destinationAddress,
    transferId,
    transferDateTime,
    travellersCount,
    isSearchInputValid,
    onDestinationAddressSelect,
    onOriginAddressSelect,
    setDestinationAddress,
    setTravellersCount,
    setOriginAddress,
    handleTransferDateTimeChange,
    handleFormInputValidate,
    handleFormSubmit,
  } = useSearchInput({
    googleMapsAPIKey,
    onSubmit,
    searchInput,
    onError,
  });

  return (
    <div className={clsx(className, 'yilu-RidesSearchForm')}>
      <div className="yilu-RidesSearchForm__Main">
        <ElementsSDK.AddressInput
          classname={clsx([
            'yilu-RidesSearchForm__InputField',
            'yilu-RidesSearchForm__Address-InputField',
          ])}
          id={originAddressInputId}
          label={isDesktop ? t('whereFrom') : undefined}
          googleMapsAPIKey={googleMapsAPIKey}
          onAddressSelect={onOriginAddressSelect}
          value={originAddress && originAddress.name}
          onError={onError}
          placeholder={t(isDesktop ? 'City, address, airport' : 'whereFrom')}
          onBlur={handleFormInputValidate(setOriginAddress)}
          Icon={ElementsSDK.Icon.Pin}
        />
        <ElementsSDK.AddressInput
          classname={clsx([
            'yilu-RidesSearchForm__InputField',
            'yilu-RidesSearchForm__Address-InputField',
          ])}
          id={destinationAddressInputId}
          label={isDesktop ? t('whereTo') : undefined}
          googleMapsAPIKey={googleMapsAPIKey}
          onAddressSelect={onDestinationAddressSelect}
          value={destinationAddress && destinationAddress.name}
          onError={onError}
          placeholder={t(isDesktop ? 'City, address, airport' : 'whereTo')}
          onBlur={handleFormInputValidate(setDestinationAddress)}
          geolocationEnabled={false}
          Icon={ElementsSDK.Icon.Pin}
        />
        <ElementsSDK.InputField.DateTime
          className={clsx([
            'yilu-RidesSearchForm__InputField',
            'yilu-RidesSearchForm__DateTime-InputField',
          ])}
          id={transferId}
          name={transferId}
          label={isDesktop ? t('when') : undefined}
          onChange={handleTransferDateTimeChange}
          format={UiUtilities.DateFormat.SHORT_DATE_WITH_TIME_WITHOUT_DAY}
          initialValue={transferDateTime}
          minDate={minDateTimeInput || dayjs().format(UiUtilities.DateFormat.SHORT_DATE)}
          controlsEnabled={false}
          placeholder={t('Select entry time')}
          onOpen={onOpenDateTimeInput}
        />
        <ElementsSDK.TravellersCounterDropdown
          className={clsx([
            'yilu-RidesSearchForm__InputField',
            'yilu-RidesSearchForm__TravellersCounter-InputField',
          ])}
          travellersCount={travellersCount}
          maxTravellersCount={maxTravellersCount}
          onChange={setTravellersCount}
          travellerCounterLabel={t('Travellers')}
          generateOptionLabel={
            !isDesktop
              ? (option) => (option.value === 1 ? t('Traveller') : t('Travellers'))
              : undefined
          }
        />
        <ElementsSDK.Button
          className={clsx([
            'yilu-RidesSearchForm__InputField',
            'yilu-RidesSearchForm__SubmitButton',
          ])}
          disabled={!isSearchInputValid}
          onClick={handleFormSubmit}
          Icon={isDesktop ? ElementsSDK.Icon.Magnifier : undefined}
        >
          {isDesktop ? undefined : t('Search')}
        </ElementsSDK.Button>
      </div>
    </div>
  );
};
