/**
 * # Lounge Booking Info
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import {
  DateFormat,
  dateToWeekIndex,
  formatCurrency,
  formatLocalDate,
} from '@yiluhub/ui-utilities';
import { Booking } from '@yiluhub/yilu-amp-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { LoungeDirections, LoungeImportantInformation } from 'modules/lounges/components';
import { HolidayExtraServiceProvider } from 'modules/lounges/utils/constants';
import { generateGuestsCountText } from 'modules/lounges/utils/getProductDetails';

import DownloadPDFButton from '../DownloadPDFButton';
import PriceBreakdown from '../PriceBreakdown';
import SaveQrToWalletButton from '../SaveQRToWalletButton';
import { useDetermineQrWalletVisibility } from './hooks';
import './styles.scss';

export type LoungeBookingInfoProps = {
  booking: Booking<'COMPLETED', 'LOUNGE'>;
};

export const LoungesBookingInfo: React.FC<LoungeBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data;
  const { cardType, last4Digits, totalPrice, subTotal, vatAmount, vatPercentage } =
    bookingData.paymentDetails;
  const { amount, currency } = totalPrice;

  const {
    qrCodeUrls,
    arrivalDate,
    loungeDetails,
    customerDetails: _customerDetails,
    numberOfPassengers,
    adults,
    children,
    infants,
    qrCodeSentBySP,
  } = bookingData;
  const { openingHours, directions, name, city, iata, terminal, airSide, importantInformation } =
    loungeDetails;
  const searchedDateOfWeek = dateToWeekIndex(arrivalDate);

  let openingHoursText = '';

  if (openingHours?.length) {
    openingHoursText =
      openingHours?.[searchedDateOfWeek].intervals.length === 0
        ? t('Closed')
        : `${openingHours[searchedDateOfWeek].intervals[0].startTime}` +
          ` - ` +
          `${openingHours[searchedDateOfWeek].intervals[0].endTime}`;
  }

  const paymentDetailsText = `${formatCurrency(amount / 100, currency)} ${t('charged to')}${
    cardType ? ` ${cardType?.toUpperCase()}` : ''
  }, ${t('ending with')} ${last4Digits}`;

  const guestsFieldText = useMemo(() => {
    return generateGuestsCountText(
      {
        adults,
        children: children ?? 0,
        infants: infants ?? 0,
      },
      t,
    );
  }, [t, adults, children, infants]);

  const isHolidayExtras = booking?.serviceProvider === HolidayExtraServiceProvider;

  const { firstName, lastName, email } = _customerDetails;

  const customerDetails = isHolidayExtras
    ? [`${firstName && `${firstName} `}${lastName}`, email]
    : [lastName, email];

  const locationTimezone =
    bookingData.loungeDetails?.location?.latitude && bookingData.loungeDetails?.location?.longitude
      ? tzlookup(
          parseFloat(bookingData.loungeDetails.location.latitude),
          parseFloat(bookingData.loungeDetails.location.longitude),
        )
      : '';
  const hasQrCodeValues = qrCodeUrls?.length > 0;

  const useQrCodes = isHolidayExtras ? !qrCodeSentBySP && hasQrCodeValues : hasQrCodeValues;

  const { canShowQrWallet } = useDetermineQrWalletVisibility(booking);

  return (
    <div className="yilu-Lounge-Booking__ConfirmationPage">
      {isHolidayExtras && qrCodeSentBySP && (
        <ElementsSDK.Message
          className="yilu-Lounge-Booking__qrCodeNotSent"
          type={ElementsSDK.MessageType.WARNING}
          icon={ElementsSDK.Icon.Warning}
          content={t('bcp.lounges.qrCode.notSent')}
        />
      )}
      <div className="yilu-Lounge-Booking__qrCodeContainer">
        <DownloadPDFButton bookingId={booking.id} />
        {useQrCodes && (
          <>
            <div className="yilu-Lounge-Booking__qrTitleWrapper">
              <ElementsSDK.Typography variant="h2">
                {t('bcp.lounges.qrCode.header')}
              </ElementsSDK.Typography>
              <ElementsSDK.Typography variant="p2">
                {t('bcp.lounges.qrCode.subHeader')}
              </ElementsSDK.Typography>
            </div>
            {canShowQrWallet && <SaveQrToWalletButton bookingId={booking.id} />}
            <div className="yilu-Lounge-Booking__qrImageWrapper">
              {qrCodeUrls?.map((qrCode, index) => {
                return (
                  <div className="yilu-Lounge-Booking__qrImage" key={index}>
                    <img
                      src={qrCode}
                      alt={t('Lounge Image')}
                      className="yilu-Lounge-Booking__qrCode"
                    />
                    <ElementsSDK.Typography variant="p3">
                      {qrCodeUrls.length > 1 ? index + 1 : ''}
                    </ElementsSDK.Typography>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <span className="yilu-BookingConfirmation__Separator" />
      <ElementsSDK.Typography variant="h2" className="yilu-Lounge-Booking__SummaryHeader">
        {t('Summary')}
      </ElementsSDK.Typography>
      <ElementsSDK.ContentBox
        mainTexts={[name]}
        subText={t('Lounge')}
        icon={<ElementsSDK.Icon.Lounge />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[booking.id]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[
          formatLocalDate(arrivalDate, DateFormat.SHORTENED_TEXT_WITH_DATE, locationTimezone),
        ]}
        subText={t('Date')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      {openingHoursText && (
        <ElementsSDK.ContentBox
          mainTexts={[openingHoursText]}
          subText={t('Opening Hours')}
          icon={<ElementsSDK.Icon.Time />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={[
          `${city} ${iata},
              ${t('Terminal {{terminal}}', { terminal })}`,
          airSide ? t('(After Security)') : t('(Before Security)'),
        ]}
        subText={t('bcp.location')}
        icon={<ElementsSDK.Icon.Pin />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[`${guestsFieldText || numberOfPassengers}`]}
        subText={t('Guest(s)')}
        icon={<ElementsSDK.Icon.Person />}
      />
      <ElementsSDK.ContentBox
        mainTexts={customerDetails}
        subText={t('Personal information')}
        icon={<ElementsSDK.Icon.Person />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[paymentDetailsText]}
        subText={t('bcp.paymentDetails')}
        icon={<ElementsSDK.Icon.Card />}
      />
      <PriceBreakdown
        subTotal={subTotal}
        vatAmount={vatAmount}
        vatPercentage={vatPercentage}
        totalPrice={totalPrice}
      />
      {importantInformation && (
        <>
          <LoungeImportantInformation content={importantInformation} />
          <span className="yilu-BookingConfirmation__Separator" />
        </>
      )}
      {directions && <LoungeDirections directions={directions} />}
    </div>
  );
};
