import { datadogRum } from '@datadog/browser-rum';

import { yiluEnv } from 'utils';

import packageJson from '../package.json';

export const initializeDatadog = () => {
  const yiluEnvVariables = yiluEnv.getVariables();
  const ep =
    yiluEnvVariables.YILU_ENTERPRISE_PARTNER &&
    yiluEnvVariables.YILU_ENTERPRISE_PARTNER.toLowerCase();
  const version = yiluEnvVariables.YILU_SIMPLETRIP_VERSION
    ? yiluEnvVariables.YILU_SIMPLETRIP_VERSION
    : packageJson.version;

  datadogRum.init({
    applicationId: 'b70505ab-7589-418e-b62d-d4589040dc03',
    clientToken: 'pub7474d6401f9d155814c6a5d2f10b8bef',
    site: 'datadoghq.com',
    service: ep,
    env: yiluEnvVariables.YILU_API_PRODUCTION_ENVIRONMENT === 'true' ? 'production' : 'staging',
    version,
    sessionSampleRate: 50, // This value should NOT be changed unless approved by SRE (cost management)
    sessionReplaySampleRate: 0, // This value should NOT be changed unless approved by SRE (cost management)
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.setGlobalContextProperty('ep', ep);
};
