import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import querystring from 'qs';
import { Location } from 'react-router-dom';

import {
  CommonQueryParams,
  getCommonQueryParams,
  parseTravellersCount,
} from 'utils/paramConverters';

import { obscurePriceParam, unobscurePriceParam } from 'modules/lounges/utils/obscure';

import { DEFAULT_LOUNGE_GUESTS, MAX_CHILDREN_LOUNGE_GUESTS, MAX_LOUNGE_GUESTS } from './constants';

export type LoungeSearchInputPageQueryParams = CommonQueryParams & {
  entryDate: string;
  maxTravellersCount?: number;
  airportDetails?: ElementsSDK.FilteredAirportDetails;
  airportIataCode?: string;
  fcle?: boolean;
  adults: number; // Number of adults
  children?: number; // Number of children
  infants?: number; // Number of infants
};

/**
 * Returns the query parameters for the Lounge Search Input page.
 *
 * @param location - location descriptor
 *
 * @returns LoungeSearchInputPageQueryParams
 */
export const getLoungeSearchInputPageQueryParams = (
  location: Location = window.location as any,
): LoungeSearchInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const sessionFcle = sessionStorage.getItem('fcle');
  const maxTravellersCount = sessionStorage.getItem('maxTravellersCount');

  const adults = parseTravellersCount(
    //travellers count added for backward compatibility
    queryParams.adults || queryParams.travellersCount,
    DEFAULT_LOUNGE_GUESTS,
    MAX_LOUNGE_GUESTS,
  );
  const children = parseTravellersCount(queryParams.children, 0, MAX_CHILDREN_LOUNGE_GUESTS);
  const infants = parseTravellersCount(queryParams.infants, 0, MAX_CHILDREN_LOUNGE_GUESTS);

  const parsedMaxTravellersCount =
    maxTravellersCount &&
    parseInt(maxTravellersCount) &&
    parseTravellersCount(parseInt(maxTravellersCount), 0, MAX_LOUNGE_GUESTS);

  const totalTravellersCount = parseTravellersCount(
    adults + children + infants,
    DEFAULT_LOUNGE_GUESTS,
    MAX_LOUNGE_GUESTS,
  );

  const isQueryParamsFcle = queryParams.fcle === 'true';
  const isSessionFcle = sessionFcle === 'true';

  const commonQueryParams = getCommonQueryParams(location);
  const searchInputLoungeParams = {
    entryDate: queryParams.entryDate
      ? queryParams.entryDate
      : dayjs().format(UiUtilities.DateFormat.SHORT_DATE_WITH_TIME),
    adults: adults,
    children: children,
    infants: infants,
    maxTravellersCount: parsedMaxTravellersCount || totalTravellersCount,
    airportDetails: queryParams.airportDetails,
    airportIataCode: queryParams.airportDetails ? undefined : queryParams.airportIataCode,
    fcle: isQueryParamsFcle || isSessionFcle,
  };

  if (isQueryParamsFcle) {
    sessionStorage.setItem('fcle', 'true');
  }

  //set maxTravellersCount in sessionStorage on initial load of FCLE
  if (isQueryParamsFcle && !isSessionFcle) {
    sessionStorage.setItem('maxTravellersCount', `${totalTravellersCount}`);
  }

  if (!searchInputLoungeParams.airportDetails) {
    delete searchInputLoungeParams.airportDetails;
  }

  if (!searchInputLoungeParams.airportIataCode) {
    delete searchInputLoungeParams.airportIataCode;
  }

  return {
    ...commonQueryParams,
    ...searchInputLoungeParams,
  };
};

export type LoungeSearchResultsPageQueryParams = LoungeSearchInputPageQueryParams;

/**
 * Returns the query parameters for the Lounge Search Results page.
 *
 * @param location - location descriptor
 *
 * @returns LoungeSearchResultsPageQueryParams
 */
export const getLoungeSearchResultsPageQueryParams = (
  location: Location = window.location as any,
): LoungeSearchResultsPageQueryParams => {
  const loungeSearchResultsPageQueryParams = getLoungeSearchInputPageQueryParams(location);

  return loungeSearchResultsPageQueryParams;
};

export type LoungeProductDetailsPageQueryParams = CommonQueryParams & {
  searchResultID?: string;
  loungeId?: string;
  variant?: string;
  entryDate?: string;
  singleProduct?: boolean;
  adults?: number;
  children?: number;
  infants?: number;
  coordinates?: ElementsSDK.Coordinates;
};

/**
 * Returns the query parameters for the Lounge Product Details page.
 *
 * @param location - location descriptor
 *
 * @returns LoungeProductDetailsPageQueryParams
 */
export const getLoungeProductDetailsPageQueryParams = (
  location: Location = window.location as any,
): LoungeProductDetailsPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const travellersCount = queryParams.travellersCount
    ? parseInt(queryParams.travellersCount)
    : undefined;

  const adults = queryParams.adults ? parseInt(queryParams.adults) : undefined;

  const children = queryParams.children ? parseInt(queryParams.children) : undefined;

  const infants = queryParams.infants ? parseInt(queryParams.infants) : undefined;

  const latitude =
    queryParams.coordinates && queryParams.coordinates.latitude
      ? parseFloat(queryParams.coordinates.latitude)
      : undefined;
  const longitude =
    queryParams.coordinates && queryParams.coordinates.longitude
      ? parseFloat(queryParams.coordinates.longitude)
      : undefined;

  const airportDetails = queryParams?.airportDetails;
  const airportLatitude = airportDetails && airportDetails.latitude;
  const airportLongitude = airportDetails && airportDetails.longitude;

  const commonQueryParams = getCommonQueryParams(location);
  const loungeProductDetailsPageParams = {
    searchResultID: queryParams.searchResultID,
    loungeId: queryParams.loungeId,
    adults: adults || travellersCount,
    children: children,
    infants: infants,
    entryDate: queryParams.entryDate,
    singleProduct: queryParams.singleProduct === 'true',
    coordinates: {
      latitude: latitude || airportLatitude,
      longitude: longitude || airportLongitude,
    } as ElementsSDK.Coordinates,
  };

  return {
    ...commonQueryParams,
    ...loungeProductDetailsPageParams,
  };
};

export type LoungeTravellerInputPageQueryParams = LoungeProductDetailsPageQueryParams;
/**
 * Returns the query parameters for the Lounge Traveller Input page.
 *
 * @param location - location descriptor
 *
 * @returns LoungeTravellerInputPageQueryParams
 */
export const getLoungeTravellerInputPageQueryParams = (
  location: Location = window.location as any,
): LoungeTravellerInputPageQueryParams => {
  const loungeTravellerInputPageQueryParams = getLoungeProductDetailsPageQueryParams(location);

  return loungeTravellerInputPageQueryParams;
};

export type LoungePaymentInputPageQueryParams = CommonQueryParams & {
  searchResultID?: string;
  loungeId?: string;
  bookingIntentId: string;
  pricing: string;
  variant?: string;
  shoppingCartId?: string;
};
/**
 * Returns the query parameters for the Lounge Payment Input page.
 *
 * @param location - location descriptor
 *
 * @returns LoungePaymentInputPageQueryParams
 */
export const getLoungePaymentInputPageQueryParams = (
  location: Location = window.location as any,
): LoungePaymentInputPageQueryParams => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams(location);

  let pricing = queryParams.pricing;

  //NOTE: This is a temporary fix to obscure the pricing param in the URL
  const pricingParamObscured = obscurePriceParam('pricing');
  if (queryParams[pricingParamObscured]) {
    const pricingParam = unobscurePriceParam(queryParams[pricingParamObscured]);
    if (pricingParam) {
      pricing = pricingParam;
    }
  }

  const loungePaymentInputPageParams = {
    searchResultID: queryParams.searchResultID,
    loungeId: queryParams.loungeId,
    bookingIntentId: queryParams.bookingIntentId,
    shoppingCartId: queryParams.shoppingCartId,
    pricing,
  };

  return {
    ...commonQueryParams,
    ...loungePaymentInputPageParams,
  };
};

export const getFlightNumberPageParam = (location: Location = window.location as any): string => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  return queryParams.flightNumber;
};
