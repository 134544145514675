import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { paramConverters, yiluTracking } from 'utils';
import { roundUpToNext15MinutesInLocalTime } from 'utils/dateUtils';
import { parseTravellersCount } from 'utils/paramConverters';

import routes from 'router/routes';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import {
  TravellersCount,
  useTravellersSelector,
} from 'modules/lounges/components/LoungeSearchForm/useTravellersSelector';
import { useOpeningTimesOptions } from 'modules/lounges/pages/ProductDetailsPage/hooks/useOpeningTimesOptions';
import { isLoungeBookable } from 'modules/lounges/utils/availability';
import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';
import {
  getSessionDeeplinkFilledState,
  sessionBookingState,
  setSessionDeeplinkFilledState,
} from 'modules/lounges/utils/sessionUtilities';

import { MAX_LOUNGE_GUESTS } from '../../../utils/constants';
import { fetchLoungeProduct } from '../../../utils/fetchLoungeProduct';
import { getLoungeTravellerInputPageQueryParams } from '../../../utils/query-params';
import useUpdateAmpSearchResult from './useUpdateAmpSearchResult';

const useLoungeProductSummaryProps = (currentLounge: SearchItem | undefined) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    name,
    currency,
    price: productPrice,
    location: productLocation,
    convertedLocalDate,
    openingHours,
    optionId,
    travellersCount: initialTravellersCount,
    providerId,
    isLocked,
    isBookable,
    airportCoordinates,
  } = getProductDetails(currentLounge);

  const isHolidayExtras = isSpHolidayExtras(providerId);

  // Additional updates are needed for Holiday Extra Lounges. Until then, we disable the edit button for Holiday Extra.
  const isEditable = !isHolidayExtras;

  const [presetTravellersCount, setPresetTravellersCount] = useState(false);
  const [loadedLounge, setLoadedLounge] = useState(false);
  const [showBacklinkNavigator, setShowBacklinkNavigator] = useState(true);
  const [entryDate, setEntryDate] = useState<string | undefined>(undefined);
  const [price, setPrice] = useState<number>(productPrice);
  const [loungeId, setLoungeId] = useState<string | undefined>(undefined);
  const [searchResultId, setSearchResultId] = useState<string | undefined>(undefined);
  const [singleProduct, setSingleProduct] = useState<boolean | undefined>(undefined);
  const [isPurchasable, setIsPurchasable] = useState(true);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [isTimeUnavailable, setIsTimeUnavailable] = useState(false);
  const [urlParamsCoordinates, setUrlParamsCoordinates] = useState<
    ElementsSDK.Coordinates | undefined
  >(undefined);

  const updateSearchResult = useUpdateAmpSearchResult();

  const [travellersCount, setTravellersCount] = useState<TravellersCount>({
    adults: initialTravellersCount.adults || 1,
    children: initialTravellersCount.children || 0,
    infants: initialTravellersCount.infants || 0,
  });

  const getTotalPassengerCount = useCallback(() => {
    let totalTravellers = travellersCount.adults + travellersCount.children;

    // LHG do not support infants
    if (isHolidayExtras) {
      totalTravellers += travellersCount.infants;
    }

    return parseTravellersCount(totalTravellers, 1, MAX_LOUNGE_GUESTS);
  }, [isHolidayExtras, travellersCount]);

  const { travellerIncrements } = useTravellersSelector(travellersCount);

  const onTravellersCountChange = useCallback(
    (newTravellerCount: TravellersCount) => {
      const totalTravellerCount = getTotalPassengerCount();
      let newTotalTravellerCount = newTravellerCount.adults + newTravellerCount.children;

      // LHG do not support infants
      if (isHolidayExtras) {
        newTotalTravellerCount += newTravellerCount.infants;
      }

      // obtain new total price
      const pricePerPerson = totalTravellerCount > 1 ? price / totalTravellerCount : price;

      setPrice(pricePerPerson * newTotalTravellerCount);

      setTravellersCount(newTravellerCount);
      setIsPurchasable(true);
      setHasBeenUpdated(true);
    },
    [isHolidayExtras, price, getTotalPassengerCount, setTravellersCount],
  );

  const travellersFields = travellerIncrements?.map((traveller) => {
    return {
      ...traveller,
      value: travellersCount[traveller.id as keyof TravellersCount] || 0,
      onChange: (value: number) => {
        const newTravellerCount = {
          ...travellersCount,
          [traveller.id]: value,
        };
        onTravellersCountChange(newTravellerCount);
      },
    };
  });

  const onDateInputChange = useCallback(
    (newDate: string | null) => {
      if (newDate && newDate !== entryDate) {
        setEntryDate(newDate);
        setIsPurchasable(true);
        setHasBeenUpdated(true);
      }
    },
    [entryDate],
  );

  // set initial values for entryDate, price, and travellersCount
  useEffect(() => {
    if (currentLounge && !loadedLounge) {
      setEntryDate(convertedLocalDate);
      setPrice(productPrice);
      setTravellersCount({
        adults: initialTravellersCount.adults || 1,
        children: initialTravellersCount.children || 0,
        infants: initialTravellersCount.infants || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLounge, loadedLounge]);

  // set initial values for loungeId, searchResultId, and urlParamsCoordinates while deep linking
  useEffect(() => {
    if (location && currentLounge) {
      const travellerInputPageQueryParams = getLoungeTravellerInputPageQueryParams(location);

      setLoungeId(travellerInputPageQueryParams.loungeId);
      setSearchResultId(travellerInputPageQueryParams.searchResultID);

      if (
        travellerInputPageQueryParams.coordinates &&
        travellerInputPageQueryParams.coordinates.latitude &&
        travellerInputPageQueryParams.coordinates.longitude
      ) {
        setUrlParamsCoordinates(travellerInputPageQueryParams.coordinates);
      } else if (airportCoordinates) {
        setUrlParamsCoordinates(airportCoordinates);
      }
    }
  }, [location, currentLounge, airportCoordinates]);

  //
  useEffect(() => {
    if (location && currentLounge && !loadedLounge) {
      const travellerInputPageQueryParams = getLoungeTravellerInputPageQueryParams(location);

      const bookingSessionId = optionId || 'lounge';
      const canSetDate =
        getSessionDeeplinkFilledState(bookingSessionId) !== sessionBookingState.FILLED;

      // set entryDate from query params if it exists, otherwise set it from currentLounge
      if (travellerInputPageQueryParams.entryDate && canSetDate) {
        // make sure the date is rounded up to the next 15 minutes
        const roundedUpDate = roundUpToNext15MinutesInLocalTime(
          travellerInputPageQueryParams.entryDate,
        );
        setEntryDate(roundedUpDate);
      }

      if (travellerInputPageQueryParams?.singleProduct) {
        setShowBacklinkNavigator(false);
        setSingleProduct(travellerInputPageQueryParams?.singleProduct);
      }
    }
  }, [location, currentLounge, onTravellersCountChange, loadedLounge, optionId]);

  // set travellersCount from query params if it exists, lounge is loaded, and the preset hasn't been set yet.
  useEffect(() => {
    if (location && currentLounge && loadedLounge && !presetTravellersCount) {
      setPresetTravellersCount(true);

      const bookingSessionId = optionId || 'lounge';
      const travellerInputPageQueryParams = getLoungeTravellerInputPageQueryParams(location);

      // check the traveller has already been filled. This is to prevent the traveller count from being overwritten
      // when the user navigates back to the lounge page from the traveller input page.
      const canSetTravellersCount =
        getSessionDeeplinkFilledState(bookingSessionId) !== sessionBookingState.FILLED;

      if (canSetTravellersCount && travellerInputPageQueryParams.adults) {
        onTravellersCountChange({
          adults: parseTravellersCount(travellerInputPageQueryParams.adults, 1, MAX_LOUNGE_GUESTS),
          children: 0,
          infants: 0,
        });
        setSessionDeeplinkFilledState(bookingSessionId, sessionBookingState.FILLED);
      }
    }
  }, [
    currentLounge,
    loadedLounge,
    location,
    onTravellersCountChange,
    presetTravellersCount,
    setPresetTravellersCount,
    travellersCount,
    optionId,
  ]);

  const openingTimesOptions = useOpeningTimesOptions({
    openingHours: openingHours,
    entryDate: entryDate,
    setIsTimeUnavailable: setIsTimeUnavailable,
  });

  const loungeProductSummaryProps = useMemo(() => {
    if (!currentLounge || !entryDate || !travellersCount || !price) {
      return null;
    }

    const isFcle = sessionStorage.getItem('fcle') === 'true';
    const totalPassengerCount = getTotalPassengerCount();

    let canContinue: boolean;

    // time is not validated on the server side, so we need to check if the time is available
    if (isTimeUnavailable) {
      canContinue = false;
    } else {
      // if the lounge fields have been updated, we can continue and validate via a fetch request
      canContinue = Boolean(hasBeenUpdated || (!isLocked && isPurchasable && isBookable));
    }

    let submitBtnLabel;
    if (canContinue) {
      submitBtnLabel = 'Confirm';
    } else if (isTimeUnavailable) {
      submitBtnLabel = 'lounge-not-available-time';
    } else if (isLocked) {
      submitBtnLabel = 'lounge-not-bookable';
    } else {
      submitBtnLabel = 'Lounges.unavailable.date';
    }

    const _props: LoungeInteractiveProductSummaryProps = {
      price,
      currency,
      name,
      entryDate,
      displayTimezone: urlParamsCoordinates
        ? tzlookup(urlParamsCoordinates.latitude, urlParamsCoordinates.longitude)
        : '',
      dateInputMinDate: dayjs().format(UiUtilities.DateFormat.SHORT_DATE),
      location: productLocation,
      maxTravellersCount: isFcle ? totalPassengerCount : MAX_LOUNGE_GUESTS,
      travellersCount,
      travellersFields,
      showBacklinkNavigator,
      openingTimesOptions,
      openingHours,
      onDateInputChange,
      isEstimatedPriceActive: !isHolidayExtras,
      isEditingDisabled: isLocked,
      isSubmitButtonDisabled: !canContinue,
      isEditable,
      airportCoordinates,
      submitBtnLabel: t(submitBtnLabel),
      onSubmit: async () => {
        yiluTracking.sendGAEvent({
          event: 'pdp_customization_clicked',
          category: 'lounge_pdp',
          label: 'lounge_pdp_customization_clicked',
        });

        const handleSearchResultUpdate = async (updateFunc: () => Promise<any>) => {
          try {
            const body = await updateFunc();
            return body ? body : null;
          } catch (error) {
            console.error('Error fetching refined search results', error);
            setIsPurchasable(false);
            return null;
          }
        };

        let newSearchResultId;
        let isError = false;

        if (hasBeenUpdated) {
          let newSearchResult;
          if (searchResultId) {
            newSearchResult = await handleSearchResultUpdate(() =>
              updateSearchResult(
                searchResultId,
                travellersCount,
                entryDate || '',
                urlParamsCoordinates,
              ),
            );
            newSearchResultId = newSearchResult?.id;
          } else if (loungeId) {
            newSearchResult = await handleSearchResultUpdate(() =>
              fetchLoungeProduct(
                loungeId,
                i18n.language,
                travellersCount,
                entryDate,
                urlParamsCoordinates,
              ),
            );
            newSearchResultId = newSearchResult?.id;
          }

          isError = newSearchResultId === null || newSearchResultId === undefined;

          if (!isError && newSearchResult?.item && !isHolidayExtras) {
            isError = !isLoungeBookable(newSearchResult);
          }

          if (isError) {
            setIsPurchasable(false);
            setHasBeenUpdated(false);
          }
        } else {
          newSearchResultId = currentLounge.id;
        }

        const catalogId = currentLounge.item.catalogId;

        const travellerInformationPageQueryParams = {
          searchResultID: newSearchResultId,
          loungeId: newSearchResultId ? undefined : currentLounge.item.productId,
          catalogId,
          singleProduct: singleProduct,
        };

        if (!isError) {
          navigate({
            pathname: routes.LOUNGES_TIP,
            search: paramConverters.getURLSearchQuery(travellerInformationPageQueryParams),
          });
        }
      },
    };
    return _props;
  }, [
    isBookable,
    isLocked,
    currentLounge,
    entryDate,
    travellersCount,
    price,
    isHolidayExtras,
    getTotalPassengerCount,
    currency,
    name,
    productLocation,
    travellersFields,
    showBacklinkNavigator,
    openingHours,
    onDateInputChange,
    isPurchasable,
    t,
    hasBeenUpdated,
    singleProduct,
    searchResultId,
    loungeId,
    updateSearchResult,
    i18n.language,
    navigate,
    isTimeUnavailable,
    openingTimesOptions,
    isEditable,
    urlParamsCoordinates,
    airportCoordinates,
  ]);

  useEffect(() => {
    if (currentLounge && !loadedLounge && entryDate && travellersCount && price) {
      setLoadedLounge(true);
    }
  }, [currentLounge, loadedLounge, entryDate, travellersCount, price]);

  return loungeProductSummaryProps;
};

export default useLoungeProductSummaryProps;
