import { useCallback } from 'react';

import { useCreateBooking } from './useCreateBooking';

type CreateBookingResponse = {
  id: string;
};

type useCreatePayRequestBookingParamsType = {
  stripe: stripe.Stripe | null;
  cardNumberStripeElement: stripe.elements.Element | null;
  clientSecret: string | null;
  bookingIntentId: string;
  paymentProviderId: string;
  userId: string;
  isTermsAccepted: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onPaymentResults: (response: CreateBookingResponse) => void;
  onError: (error: Error) => void;
  setCardHolderDirty: (cardHolderDirty: boolean) => void;
  isFormValid: boolean;
  touchPointId: string;
};

export const useCreatePaymentFormBooking = ({
  stripe,
  cardNumberStripeElement,
  clientSecret,
  bookingIntentId,
  paymentProviderId,
  userId,
  isTermsAccepted,
  setIsLoading,
  onPaymentResults,
  onError,
  setCardHolderDirty,
  isFormValid,
  touchPointId,
}: useCreatePayRequestBookingParamsType) => {
  const { createBooking } = useCreateBooking({
    bookingIntentId,
    paymentProviderId,
    userId,
    onPaymentResults,
    touchPointId,
  });

  const handleFormSubmit = useCallback(async () => {
    setCardHolderDirty(true);

    if (!isFormValid) {
      return;
    }

    if (!stripe || !clientSecret || !isTermsAccepted || !cardNumberStripeElement) {
      onError(new Error('Missing data while creating a booking!'));
      return;
    }

    if (!stripe || !clientSecret) {
      onError(new Error('Missing data while creating a booking!'));
      return;
    }

    try {
      setIsLoading(true);

      const stripeSetup = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardNumberStripeElement },
      });

      await createBooking(stripeSetup);
    } catch (error) {
      onError(error as Error);
    } finally {
      setIsLoading(false);
    }
  }, [
    setCardHolderDirty,
    isFormValid,
    stripe,
    clientSecret,
    isTermsAccepted,
    cardNumberStripeElement,
    onError,
    setIsLoading,
    createBooking,
  ]);

  return { handleFormSubmit };
};
