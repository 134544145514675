import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

const Imprint: React.FC = () => (
  <>
    <ElementsSDK.Typography variant="p2">
      <br />
      <b>Yilu Travel Services GmbH</b>
    </ElementsSDK.Typography>
    <br />
    <ElementsSDK.Typography variant="p2">
      <b>Adresse</b>
      <br />
      Gormannstr. 14
      <br />
      10119 Berlin
      <br />
      Germany
    </ElementsSDK.Typography>
    <br />
    <ElementsSDK.Typography variant="p2">
      <b>Email</b>{' '}
      <ElementsSDK.Link inline style={{ fontSize: 'unset' }} href="mailto:info@yiluhub.com">
        info@yiluhub.com
      </ElementsSDK.Link>
      <br />
      <b>Internet</b>{' '}
      <ElementsSDK.Link inline style={{ fontSize: 'unset' }} href="https://yiluhub.com/">
        https://yiluhub.com/
      </ElementsSDK.Link>
      <br />
    </ElementsSDK.Typography>

    <ElementsSDK.Typography variant="p2">
      <b>Handelsregister</b> Charlottenburg
      <br />
      <b>Handelsregister-Nummer </b> HRB 198186 B<br />
      <b>Sitz</b> Berlin
      <br />
    </ElementsSDK.Typography>

    <ElementsSDK.Typography variant="p2">
      <b>Geschäftsführer</b>
      <br />
      Mark Meusch
    </ElementsSDK.Typography>
    <ElementsSDK.Typography variant="p2">
      <b>Verantwortlich für den Inhalt</b>
      <br />
      Mark Meusch
    </ElementsSDK.Typography>
  </>
);

export default Imprint;
