import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import {
  TravellersCount,
  useTravellersSelector,
} from 'modules/lounges/components/LoungeSearchForm/useTravellersSelector';

import { LoungeSearchFormProps } from './types';

const DEFAULT_INITIAL_TRAVELLERS_COUNT = 1;

export type UseLoungeSearchFormParams = Pick<
  LoungeSearchFormProps,
  'onSubmit' | 'searchInput' | 'onError'
>;

export const useLoungeSearchForm = ({ searchInput, onSubmit }: UseLoungeSearchFormParams) => {
  const locale = UiUtilities.getYiluConfigLocale();

  const {
    entryDate = null,
    airport = null,
    adults = DEFAULT_INITIAL_TRAVELLERS_COUNT,
    children,
    infants,
  } = searchInput || {};

  // isFormValid
  const [isFormValid, setIsFomValid] = useState(false);
  // enterDateTime (use a default time since in MVP we will only use Date)
  const [enterDateTime, setEnterDateTime] = useState<string | null>(entryDate ? entryDate : null);
  // selectedAirport
  const [selectedAirport, setSelectedAirport] = useState<ElementsSDK.FilteredAirportDetails | null>(
    airport,
  );
  // selectedAirportName
  const [selectedAirportName, setSelectedAirportName] = useState<string | null>(
    airport && airport.localizations[locale].name,
  );

  const [travellersCount, setTravellersCount] = useState<TravellersCount>({
    adults: adults || 1,
    children: children || 0,
    infants: infants || 0,
  });

  const { travellerIncrements } = useTravellersSelector(travellersCount);

  const travellersFields = travellerIncrements?.map((traveller) => {
    return {
      ...traveller,
      value: travellersCount[traveller.id as keyof TravellersCount] || 0,
      onChange: (value: number) => {
        setTravellersCount({
          ...travellersCount,
          [traveller.id]: value,
        });
      },
    };
  });

  useEffect(() => {
    if (!enterDateTime || !selectedAirport || !dayjs(enterDateTime).isValid()) {
      return setIsFomValid(false);
    }

    setIsFomValid(true);
  }, [enterDateTime, selectedAirport]);

  // set the airport details and name
  const handleOnAirportSelect = useCallback(
    (airport: ElementsSDK.FilteredAirportDetails) => {
      setSelectedAirport(airport);
      setSelectedAirportName(airport.localizations[locale].name);
    },
    [locale],
  );

  // handle click on submit button
  const handleOnSubmitButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (isFormValid && selectedAirport) {
        onSubmit({
          entryDate:
            enterDateTime &&
            dayjs(enterDateTime).format(UiUtilities.DateFormat.SHORT_DATE_WITH_TIME),
          airport: selectedAirport,
          adults: travellersCount.adults,
          children: travellersCount.children,
          infants: travellersCount.infants,
        });
      }
    },
    [selectedAirport, enterDateTime, travellersCount, onSubmit, isFormValid],
  );

  return {
    travellersFields,
    handleOnSubmitButtonClick,
    handleOnAirportSelect,
    selectedAirportName,
    isFormValid,
    enterDateTime,
    setEnterDateTime,
  };
};
