/**
 * # Ride Interactive Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate, useIsDesktop } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TermsCheck from '../../../../components/TermsCheck';
import './styles.scss';
import { RideInteractiveProductSummaryProps, searchState } from './types';

export const RideInteractiveProductSummary: React.FC<RideInteractiveProductSummaryProps> = ({
  price,
  currency,
  onSubmit,
  isLoading,
  isDisabled,
  submitBtnLabel,
  children,
  whereTo,
  whereFrom,
  travelDate,
  travellersCount,
  isEstimatedPriceActive,
  hasFooter,
  carrierLogoUrl,
  providerClassification,
  carrierName,
  displayingTimezone,
  onSearch,
  showEdit = false,
  showPriceSummary = true,
  googleMapsAPIKey,
  termsDetails,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const [isEditable, setIsEditable] = useState(false);

  const whereFromIntialValue = ElementsSDK.useAddressDetails(googleMapsAPIKey, whereFrom);
  const whereToInitialValue = ElementsSDK.useAddressDetails(googleMapsAPIKey, whereTo);

  const [search, setSearch] = useState<searchState>({
    whereTo: whereToInitialValue,
    whereFrom: whereFromIntialValue,
    travelDate,
    travellersCount,
  });

  const [inputSearch, setInputSearch] = useState<searchState>({
    whereTo: whereToInitialValue,
    whereFrom: whereFromIntialValue,
    travelDate,
    travellersCount,
  });

  useEffect(() => {
    setSearch((search) => ({
      ...search,
      whereTo: whereToInitialValue,
      whereFrom: whereFromIntialValue,
    }));
    setInputSearch((search) => ({
      ...search,
      whereTo: whereToInitialValue,
      whereFrom: whereFromIntialValue,
    }));
  }, [whereFromIntialValue, whereToInitialValue]);

  const date = formatLocalDate(
    search.travelDate!.replace(/T.*/, ''),
    'ddd, DD MMM YYYY',
    displayingTimezone,
  );
  const time = formatLocalDate(search.travelDate, DateFormat.TIME, displayingTimezone);

  const onCrossPressed = () => {
    setSearch({ ...inputSearch });
    setIsEditable(false);
  };

  const onSearchPressed = () => {
    setIsEditable(false);
    onSearch(search.whereTo, search.whereFrom, search.travelDate, search.travellersCount);
    setInputSearch({ ...search });
  };
  const ExtendedProductSummary = (
    <>
      {isEditable ? (
        <div className="yilu-ProductSummary__Ride__Btn__Editable--mobile">
          <button onClick={onCrossPressed} className="yilu-ProductSummary__Ride__Btn--mobile">
            <ElementsSDK.Icon.Cross className="yilu-ProductSummary__Ride__Icon_Editable--mobile" />
          </button>
        </div>
      ) : (
        <ElementsSDK.Typography variant="h6" className="yilu-ProductSummaryRide__Header">
          {t('interactiveProductSummary.title')}
          {!isDesktop && showEdit && (
            <button
              onClick={() => setIsEditable(true)}
              className="yilu-ProductSummary__Ride__Btn--mobile"
            >
              <ElementsSDK.Icon.EditOutline className="yilu-ProductSummary__Ride__Icon--mobile" />
            </button>
          )}
        </ElementsSDK.Typography>
      )}
      <div className="yilu-ProductSummaryRide__InfoContainer">
        {isEditable ? (
          <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
            <ElementsSDK.AddressInput
              onAddressSelect={(airport) =>
                setSearch({ ...search, whereFrom: airport as ElementsSDK.GoogleSearchAddress })
              }
              placeholder={search.whereFrom?.name}
              Icon={ElementsSDK.Icon.PinOutline}
              value={search.whereFrom?.name}
              googleMapsAPIKey={googleMapsAPIKey}
            />
          </div>
        ) : (
          <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__RideName">
            <ElementsSDK.Typography variant="h4" className="yilu-ProductSummaryRide__Value">
              {search.whereFrom?.name}
            </ElementsSDK.Typography>
          </div>
        )}
        <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__Location">
          {isEditable ? (
            <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
              <ElementsSDK.AddressInput
                onAddressSelect={(airport) =>
                  setSearch({ ...search, whereTo: airport as ElementsSDK.GoogleSearchAddress })
                }
                placeholder={search.whereTo?.name}
                Icon={ElementsSDK.Icon.PinOutline}
                value={search.whereTo?.name}
                googleMapsAPIKey={googleMapsAPIKey}
              />
            </div>
          ) : (
            <ElementsSDK.Typography size="medium" className="yilu-ProductSummaryRide__Value">
              {search.whereTo?.name}
            </ElementsSDK.Typography>
          )}
        </div>
        <div
          className={clsx(!isDesktop && 'yilu-ProductSummaryRide__InputContainer', {
            'yilu-ProductSummaryRide__InputContainer__Date': !isEditable,
          })}
        >
          <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__Date">
            {isEditable ? (
              <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
                <ElementsSDK.InputField.DateTime
                  value={search.travelDate}
                  onChange={(date: string) => setSearch({ ...search, travelDate: date })}
                  title={t('interactiveProductSummary.dateTime')}
                />
              </div>
            ) : (
              <ElementsSDK.Typography size="large" className="yilu-ProductSummaryRide__Value">
                {date}, {time}
              </ElementsSDK.Typography>
            )}
          </div>

          <div className="yilu-ProductSummaryRide__Section yilu-ProductSummaryRide__Travellers">
            {isEditable ? (
              <div className="yilu-ProductSummary__Ride__Btn--mobile yilu-ProductSummary__Ride__Input--mobile">
                <ElementsSDK.InputField.TextInput
                  icon={<ElementsSDK.Icon.PersonOutline />}
                  className="yilu-productSummaryRide__Passenger__Icon"
                  value={search.travellersCount.toString()}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      travellersCount: parseInt((e.target as HTMLInputElement).value),
                    })
                  }
                  type="number"
                />
              </div>
            ) : (
              <ElementsSDK.Typography size="medium" className="yilu-ProductSummaryRide__Value">
                {t('interactiveProductSummary.rides.passengerField.desktop', {
                  guests: search.travellersCount,
                })}
              </ElementsSDK.Typography>
            )}
          </div>
        </div>
      </div>
      {isDesktop && (
        <div className="yilu-ProductSummary__Info">
          <ul>
            <li className="yilu-ProductSummary__Row">
              <ElementsSDK.Typography size="medium" className="yilu-ProductSummary__Column">
                {t('Operator')}
              </ElementsSDK.Typography>
              <div className="yilu-ProductSummary__OperatorLogoWrapper">
                <img
                  className="yilu-ProductSummary__OperatorLogo"
                  src={carrierLogoUrl}
                  alt={t('Operator logo')}
                />
                <ElementsSDK.Typography size="medium" className="yilu-ProductSummary__MinorOffset">
                  {carrierName}
                </ElementsSDK.Typography>
              </div>
            </li>
            {providerClassification && (
              <li className="yilu-ProductSummary__Row">
                <ElementsSDK.Typography size="medium" className="yilu-ProductSummary__Column ">
                  {t('Class')}
                </ElementsSDK.Typography>
                <ElementsSDK.Typography size="medium">
                  {providerClassification}
                </ElementsSDK.Typography>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );

  return (
    <div
      className={clsx({
        'yilu-ProductSummaryRides--mobile': !isDesktop,
        'yilu-ProductSummaryRides--desktop': isDesktop,
        'yilu-ProductSummaryRides': !isEditable,
      })}
    >
      {ExtendedProductSummary}
      {children}
      {isEditable ? (
        <ElementsSDK.Button
          className="yilu-PriceSummary__Button"
          disabled={isDisabled}
          onClick={onSearchPressed}
          loading={isLoading}
        >
          {t('Search')}
        </ElementsSDK.Button>
      ) : (
        showPriceSummary && (
          <ElementsSDK.PriceSummary
            className={clsx('yilu-ProductSummaryRide__PriceSummary', {
              'yilu-ProductSummaryRide__PriceSummary--footer': !isDesktop || hasFooter,
            })}
            price={price}
            currency={currency}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onSubmit={() => onSubmit(search.travellersCount, search.travelDate!)}
            isEstimatedPriceActive={isEstimatedPriceActive}
            submitBtnLabel={submitBtnLabel}
            isTotalHighlighted={true}
            showTravellerCount={!isDesktop}
            travellerCount={travellersCount}
            termsComponent={
              termsDetails && (
                <TermsCheck
                  termsUrl={termsDetails?.termsUrl || ''}
                  productDetails={termsDetails?.productDetails}
                />
              )
            }
          />
        )
      )}
    </div>
  );
};
