import { SearchItem } from '@yiluhub/yilu-amp-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils/index';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';
import { ParkingPaymentInputPageQueryParams } from 'modules/parking/utils/query-params';

export function usePaymentInfoFormProps(
  currentParking: SearchItem | undefined,
  paymentFormRef: React.RefObject<HTMLFormElement>,
  parkingPaymentInputPageQueryParams: ParkingPaymentInputPageQueryParams,
) {
  const navigate = useNavigate();

  const paymentInformationFormProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    const parkingDetails = getParkingDetails(currentParking);

    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      amount: {
        price: parkingDetails.price,
        currency: parkingDetails.currency,
      },
      stripeMerchantLabel:
        parkingDetails.providerId === 'PARK_VIA'
          ? 'Park Via'
          : parkingDetails.address || parkingDetails.providerId,
      productTrackingId: 'parking',
      serviceProviderId: parkingDetails.providerId,
      bookingIntentId: parkingPaymentInputPageQueryParams.bookingIntentId,
      userId: parkingPaymentInputPageQueryParams.userId as string,
      touchPointId: parkingPaymentInputPageQueryParams.touchpoint as string,
      shoppingCartId: parkingPaymentInputPageQueryParams.shoppingCartId!,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        yiluTracking.sendPaymentSuccessfulEvent(parkingPaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.PARKING_BCP,
          search: paramConverters.getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [paymentFormRef, currentParking, parkingPaymentInputPageQueryParams, navigate]);
  return paymentInformationFormProps;
}
