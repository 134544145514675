import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate, useIsDesktop } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import { generateGuestsCountText } from 'modules/lounges/utils/getProductDetails';

export const DisplayFields: React.FC<
  Pick<LoungeInteractiveProductSummaryProps, 'travellersCount' | 'entryDate' | 'displayTimezone'>
> = ({ travellersCount, entryDate, displayTimezone }) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const guestsFieldText = useMemo(
    () =>
      generateGuestsCountText(
        {
          adults: travellersCount.adults,
          children: travellersCount.children || 0,
          infants: travellersCount.infants || 0,
        },
        t,
      ),
    [travellersCount, t],
  );

  const dateLabel = formatLocalDate(
    entryDate,
    DateFormat.SHORTENED_TEXT_WITH_DATE,
    displayTimezone,
  );

  return (
    <>
      <div className="yilu-ProductSummaryLounge__Section yilu-ProductSummaryLounge__Date">
        <ElementsSDK.Typography
          testid="interactiveProductSummary__Date"
          size="large"
          className="yilu-ProductSummaryLounge__Value"
        >
          {isDesktop && t('interactiveProductSummary.dateField', { date: dateLabel })}
          {!isDesktop && dateLabel}
        </ElementsSDK.Typography>
      </div>

      <div className="yilu-ProductSummaryLounge__Section yilu-ProductSummaryLounge__Travellers">
        <ElementsSDK.Typography
          testid="interactiveProductSummary__Travellers"
          size="large"
          className={clsx('yilu-ProductSummaryLounge__Value')}
        >
          {guestsFieldText}
        </ElementsSDK.Typography>
      </div>
    </>
  );
};
