import { GetBookingByBookingIdResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import { getVariables } from 'utils/yiluEnv';

const POLLING_DELAY_TIME = 2000;
const MAX_POLLING_NUMBER_OF_RETRIES = 15;

export type UseGetBookingParams = {
  bookingId: string;
  onError?: (error: Error) => unknown;
  onResponse?: (booking: GetBookingByBookingIdResponse) => unknown;
};

export function useGetBooking({ bookingId, onError, onResponse }: UseGetBookingParams) {
  const [booking, setBooking] = useState<GetBookingByBookingIdResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const isMounted = useRef<boolean>(false);
  const poll = useRef<NodeJS.Timeout | null>(null);
  const yiluEnv = getVariables();

  let retryCount = 0;

  const stopPolling = () => {
    if (isMounted.current && poll.current) {
      clearTimeout(poll.current);
      poll.current = null;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const runPolling = () => {
      const timeoutId = setTimeout(() => {
        axios
          .get<GetBookingByBookingIdResponse>(
            `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/bookings/${bookingId}`,
            {
              data: {}, // send this blank to make content-type: application/json
            },
          )
          .then((response) => {
            const booking = response.data;

            if (!booking) {
              throw new Error('Booking empty response');
            }

            const state = booking.state;

            retryCount++;
            setBooking(booking);
            onResponse && onResponse(booking);

            (state === 'PENDING' || state === 'STARTED') &&
            retryCount <= MAX_POLLING_NUMBER_OF_RETRIES
              ? runPolling()
              : stopPolling();
          })
          .catch((error) => {
            onError && onError(error);
            stopPolling();
          });
      }, POLLING_DELAY_TIME + retryCount * 1000); // Delay starts at 3 seconds and increases by 1 second for each retry
      poll.current = timeoutId;
    };

    isMounted.current = true;
    setIsLoading(true);

    if (!poll.current) runPolling();

    return () => {
      isMounted.current = false;
      stopPolling();
    };
  }, [bookingId, onError, onResponse, retryCount, yiluEnv.YILU_AMP_BACKEND_URL]);

  return {
    booking,
    isLoading,
  };
}
