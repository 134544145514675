/**
 * # Lounges Payment Input Page Hooks
 *
 *
 */
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useCurrentProduct } from 'hooks';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { getProductDetails } from 'modules/lounges/utils/getProductDetails';

import { useLoungeProduct, useRedirectOnLoungeNotFound } from '../../../hooks';
import { PRICING_OPTION } from '../../../utils/constants';
import { getLoungePaymentInputPageQueryParams } from '../../../utils/query-params';
import useLoungePaymentInformationFormProps from './useLoungePaymentInformationFormProps';
import useLoungeProductSummaryProps from './useLoungeProductSummaryProps';
import useTracking from './useTracking';

/**
 * Retrieve data for the lounge payment input page.
 */
export const useLoungePaymentInputPage = () => {
  const location = useLocation();

  const loungePaymentInputPageQueryParams = useMemo(() => {
    return getLoungePaymentInputPageQueryParams(location);
  }, [location]);

  const { loungeId, searchResultID, pricing } = loungePaymentInputPageQueryParams;
  const { loungeProduct } = useLoungeProduct(loungeId);
  const { currentProduct } = useCurrentProduct(searchResultID);
  useRedirectOnLoungeNotFound(loungeId, searchResultID);

  const currentLounge = currentProduct || loungeProduct;

  const paymentFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(currentLounge);

  useTracking(isPageReady);

  const productSummaryProps = useLoungeProductSummaryProps(currentLounge, paymentFormRef, pricing);

  const paymentInformationFormProps = useLoungePaymentInformationFormProps(
    currentLounge,
    paymentFormRef,
    loungePaymentInputPageQueryParams,
    pricing,
  );

  return {
    isPageReady,
    paymentInformationFormProps,
    productSummaryProps,
  };
};

export function getActualLoungePrice(currentLounge: SearchItem, pricingOption: string) {
  const {
    economyPremiumPriceAmount,
    economyPremiumPriceCurrency,
    otherAirlinePriceAmount,
    otherAirlinePriceCurrency,
  } = getProductDetails(currentLounge);

  let price;
  let currency;
  if (pricingOption === PRICING_OPTION.PREMIUM && economyPremiumPriceAmount) {
    price = economyPremiumPriceAmount / 100;
    currency = economyPremiumPriceCurrency;
  } else if (pricingOption === PRICING_OPTION.OTHER && otherAirlinePriceAmount) {
    price = otherAirlinePriceAmount / 100;
    currency = otherAirlinePriceCurrency;
  }

  return {
    price,
    currency,
  };
}
