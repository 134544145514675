/**
 * # Lounge Interactive Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate, useIsDesktop } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DisplayFields } from 'modules/lounges/components/LoungeInteractiveProductSummary/DisplayFields';
import { EditableFields } from 'modules/lounges/components/LoungeInteractiveProductSummary/EditableFields';

import TermsCheck from '../../../../components/TermsCheck';
import './styles.scss';
import { LoungeInteractiveProductSummaryProps } from './types';

// This is the time interval for the lounge time picker in minutes
export const LOUNGE_TIME_INTERVAL = 15;

export const LoungeInteractiveProductSummary: React.FC<LoungeInteractiveProductSummaryProps> = ({
  price,
  currency,
  onSubmit,
  isLoading,
  isEditingDisabled,
  isSubmitButtonDisabled,
  submitBtnLabel,
  children,
  entryDate,
  displayTimezone,
  name,
  maxTravellersCount,
  travellersFields,
  onDateInputChange,
  dateInputMaxDate,
  travellerSelectorProps,
  location,
  isEditable,
  isEstimatedPriceActive,
  hasFooter,
  openingHours,
  openingTimesOptions,
  termsDetails,
  travellersCount,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useIsDesktop();

  const ExtendedProductSummary = (
    <>
      <ElementsSDK.Typography variant="h6" className="yilu-ProductSummaryLounge__Header">
        {t('interactiveProductSummary.title')}
      </ElementsSDK.Typography>
      <div className="yilu-ProductSummaryLounge__InfoContainer">
        <div className="yilu-ProductSummaryLounge__Section yilu-ProductSummaryLounge__LoungeName">
          <ElementsSDK.Typography variant="h4" className="yilu-ProductSummaryLounge__Value">
            {name}
          </ElementsSDK.Typography>
        </div>
        <div className="yilu-ProductSummaryLounge__Section yilu-ProductSummaryLounge__Location">
          <ElementsSDK.Typography size="small" className="yilu-ProductSummaryLounge__Value">
            {location}
          </ElementsSDK.Typography>
        </div>
        <div className={clsx(!isDesktop && 'yilu-ProductSummaryLounge__InputContainer')}>
          {isEditable ? (
            <EditableFields
              entryDate={entryDate}
              isEditingDisabled={isEditingDisabled}
              maxTravellersCount={maxTravellersCount}
              travellersFields={travellersFields}
              onDateInputChange={onDateInputChange}
              dateInputMaxDate={dateInputMaxDate}
              travellerSelectorProps={travellerSelectorProps}
              openingHours={openingHours}
              openingTimesOptions={openingTimesOptions}
            />
          ) : (
            <DisplayFields
              travellersCount={travellersCount}
              entryDate={entryDate}
              displayTimezone={displayTimezone}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className="yilu-ProductSummaryLounge">
      {ExtendedProductSummary}
      {children}
      <ElementsSDK.PriceSummary
        className={clsx('yilu-ProductSummaryLounge__PriceSummary', {
          'yilu-ProductSummaryLounge__PriceSummary--footer': !isDesktop || hasFooter,
        })}
        price={price}
        currency={currency}
        isLoading={isLoading}
        isDisabled={isSubmitButtonDisabled}
        onSubmit={() => onSubmit(travellersCount, entryDate)}
        isEstimatedPriceActive={isEstimatedPriceActive}
        submitBtnLabel={submitBtnLabel}
        termsComponent={
          termsDetails && (
            <TermsCheck
              termsUrl={termsDetails?.termsUrl || ''}
              productDetails={termsDetails?.productDetails}
            />
          )
        }
      />
    </div>
  );
};
