/**
 * # Rides Traveller Input Page Hooks
 *
 *
 */
import { useCurrentProduct } from 'hooks';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { sendGAEvent } from 'utils/tracking';
import { getVariables } from 'utils/yiluEnv';

import { useAmpRidesResultData } from 'modules/rides/utils/useAmpRidesResultData';

import { getRidesTravellerInputPageQueryParams } from '../../../utils/query-params';
import useReservationInformationFormProps from './useReservationInformationFormProps';
import useTracking from './useTracking';

/**
 * Retrieve data for the rides traveller input page.
 */
export const useTravellerInputPage = () => {
  const location = useLocation();
  const { GOOGLE_MAPS_KEY } = getVariables();
  const [isReservationUpdateLoading, setIsReservationUpdateLoading] = useState(false);
  const travellerInputPageQueryParams = useMemo(() => {
    return getRidesTravellerInputPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(travellerInputPageQueryParams.searchResultID);
  const {
    originLatitude,
    originLongitude,
    carrierName,
    carrierLogoUrl,
    providerClassification,
    destinationLatitude,
    destinationLongitude,
    departureDateTime,
    price,
    currency,
    travellersCount,
  } = useAmpRidesResultData(currentProduct);

  const reservationFormRef = useRef<HTMLFormElement>(null);

  const isPageReady = Boolean(currentProduct);

  useTracking(isPageReady);
  const reservationInformationFormProps = useReservationInformationFormProps(
    currentProduct,
    reservationFormRef,
    travellerInputPageQueryParams,
    setIsReservationUpdateLoading,
  );

  const rideProductInteractiveSummaryProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }
    const _props = {
      whereFrom: `${originLatitude},${originLongitude}`,
      whereTo: `${destinationLatitude},${destinationLongitude}`,
      travelDate: departureDateTime,
      isEstimatedPriceActive: false,
      price,
      currency,
      travellersCount,
      carrierName,
      carrierLogoUrl,
      providerClassification,
      displayingTimezone: tzlookup(destinationLatitude, destinationLongitude),
      googleMapsAPIKey: GOOGLE_MAPS_KEY!,
      isLoading: isReservationUpdateLoading,
      onSubmit() {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
          sendGAEvent({
            event: 'tip_cta_clicked',
            category: 'ride-tip',
            label: 'ride_tip_cta_clicked',
          });
        }
      },
      onSearch() {},
    };
    return _props;
  }, [
    currentProduct,
    carrierLogoUrl,
    carrierName,
    destinationLatitude,
    destinationLongitude,
    isReservationUpdateLoading,
    originLatitude,
    originLongitude,
    travellersCount,
    currency,
    price,
    providerClassification,
    departureDateTime,
    GOOGLE_MAPS_KEY,
  ]);

  return {
    isPageReady,
    travellerInformationFormProps: reservationInformationFormProps,
    rideProductInteractiveSummaryProps,
  };
};
