/**
 * # Lounges Opening Hours
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OpeningHours } from '../../types';
import './styles.scss';

export type LoungesOpeningHoursProps = {
  openingHours: OpeningHours[];
  searchedDateOfWeek: number;
  openingTimesMessage?: string;
  checkInTime?: string;
};

const LoungeOpeningHours: React.FC<LoungesOpeningHoursProps> = ({
  openingHours,
  searchedDateOfWeek,
  openingTimesMessage,
  checkInTime,
}) => {
  const { t } = useTranslation();
  const [openingHoursExpanded, setOpeningHoursExpanded] = useState(false);

  const openingHourListItem = (openingHour: OpeningHours, index?: number) => {
    if (!openingHour) return null;

    const day = t(
      `Calendar.${
        openingHour.day.charAt(0).toUpperCase() + openingHour.day.toLowerCase().slice(1)
      }`,
    );
    return (
      <div key={index} className="yilu-Lounges__OpeningHour">
        <ElementsSDK.Typography>{day}</ElementsSDK.Typography>
        <div className="yilu-Lounges__OpeningHourTime">
          <ElementsSDK.Typography>
            {openingHour.intervals.length === 0 ? (
              t('Closed')
            ) : (
              <>
                {openingHour.intervals[0].startTime}
                {' - '}
                {openingHour.intervals[0].endTime}
              </>
            )}
          </ElementsSDK.Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      <ElementsSDK.Typography variant="h2">{t('Opening Hours')}</ElementsSDK.Typography>
      {openingTimesMessage && (
        <ElementsSDK.Typography variant="h4">{openingTimesMessage}</ElementsSDK.Typography>
      )}
      {checkInTime && <ElementsSDK.Typography variant="p1">{checkInTime}</ElementsSDK.Typography>}
      <>{!openingHoursExpanded && openingHourListItem(openingHours[searchedDateOfWeek])}</>

      <div className="yilu-Lounges_CollapseButtonContainer">
        <ElementsSDK.Accordion
          label={{ open: t('See less'), closed: t('See more') }}
          onChangeListener={setOpeningHoursExpanded}
          expandUp
          labelVariant="p1"
        >
          {openingHours.map((openingHour, index) => {
            return openingHourListItem(openingHour, index);
          })}
        </ElementsSDK.Accordion>
      </div>
    </>
  );
};

export default LoungeOpeningHours;
