import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { yiluEnv } from 'utils/index';

import { useLegalModalContext } from 'context';

import ItalianBottomBanner from 'components/ItalianBottomBanner';

import { LegalContentType } from '../../applicationConstants';
import Legal from '../../widgets/Legal';
import Footer from '../Footer';
import Header from '../Header';
import './styles.scss';

enum PartnerId {
  LUFTHANSA = 'LH',
  SWISS = 'LX',
  AUSTRIAN = 'OS',
  BRUSSELS = 'SN',
  EUROWINGS = 'EW',
  DISCOVER = '4Y',
  AIRDOLOMITI = 'EN',
}

type LayoutProps = {
  children?: React.ReactNode;
  contentClassName?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, contentClassName }) => {
  const { t } = useTranslation();
  const {
    isLegalModalShown,
    showLegalBackBtn,
    trackingContentType,
    title,
    openLegalModal,
    closeLegalModal,
  } = useLegalModalContext();
  const yiluEnvVariables = yiluEnv.getVariables();
  const partnerId = yiluEnvVariables.YILU_ENTERPRISE_PARTNER || 'LH';

  return (
    <div className="layout">
      <Header />
      <div className={clsx('content-container', contentClassName)}>
        {children}
        <Outlet />
      </div>
      <Footer />
      <Legal
        isVisible={isLegalModalShown}
        contentType={trackingContentType}
        title={title}
        showClose={showLegalBackBtn}
        handleCloseBackBtn={() => {
          openLegalModal(LegalContentType.TRACKING, t('cookiesBanner.trackingPreferences'));
        }}
        handleClose={() => closeLegalModal()}
      />
      {partnerId === PartnerId.AIRDOLOMITI && <ItalianBottomBanner />}
    </div>
  );
};

export default Layout;

export { default as SummaryLayout } from './SummaryLayout';
