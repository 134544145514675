import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import {
  CurrentProductContextProvider,
  EmbeddedTIContextProvider,
  LegalModalContextProvider,
} from 'context';

import Layout from 'components/Layout';
import ScrollBehavior from 'components/ScrollBehavior';

import Analytics from 'widgets/Analytics';
import GlobalDefaults from 'widgets/GlobalDefaults';

import * as Page from 'modules';
import { LoungeProductContextProvider } from 'modules/lounges/context';

import RedirectPageOnCondition from './RedirectPageOnCondition';
import routes from './routes';
import { useTIRouterProps } from './useTIRouterProps';

export type RouterProps = {
  initialPath?: string;
};

const Router: React.FC<RouterProps> = ({ initialPath }) => {
  const {
    defaultPath,
    startTracking,
    hasEnabledLoungeServiceProviders,
    hasEnabledParkingServiceProviders,
    hasEnabledBusServiceProviders,
    hasEnabledRideServiceProviders,
    isHighPassEnabled,
    isEmbeddedEnabled,
    isMeiliEnabled,
    isLandingPageEnabled,
  } = useTIRouterProps(initialPath);

  return (
    <BrowserRouter>
      <ScrollBehavior />
      <GlobalDefaults>
        {startTracking && <Analytics />}
        {isEmbeddedEnabled ? (
          <EmbeddedTIContextProvider>
            <Routes>
              <Route
                path={routes.MEILI_CAR_RENTAL}
                element={
                  <RedirectPageOnCondition
                    condition={isMeiliEnabled}
                    redirectPath={defaultPath}
                    PageElement={<Page.CarRentalPage />}
                  />
                }
              />
            </Routes>
          </EmbeddedTIContextProvider>
        ) : (
          <LegalModalContextProvider>
            <Routes>
              <Route element={<Layout />}>
                {/* AIRPORT TRANSFER */}
                <Route
                  path={routes.AIRPORT_TRANSFER_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferSearchResultsPage />}
                    />
                  }
                />
                <Route
                  element={
                    <CurrentProductContextProvider>
                      <Outlet />
                    </CurrentProductContextProvider>
                  }
                >
                  <Route
                    path={routes.AIRPORT_TRANSFER_PDP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledBusServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.AirportTransferProductDetailsPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.AIRPORT_TRANSFER_TIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledBusServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.AirportTransferTravellerInputPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.AIRPORT_TRANSFER_PIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledBusServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.AirportTransferPaymentInputPage />}
                      />
                    }
                  />
                </Route>
                <Route
                  path={routes.AIRPORT_TRANSFER_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferBookingConfirmationPage />}
                    />
                  }
                />
                {/* RIDES */}
                <Route
                  path={routes.RIDES_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.RIDES_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesSearchResultsPage />}
                    />
                  }
                />
                <Route
                  element={
                    <CurrentProductContextProvider>
                      <Outlet />
                    </CurrentProductContextProvider>
                  }
                >
                  <Route
                    path={routes.RIDES_TIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledRideServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.RidesTravellerInputPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.RIDES_PIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledRideServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.RidesPaymentInputPage />}
                      />
                    }
                  />
                </Route>
                <Route
                  path={routes.RIDES_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesBookingConfirmationPage />}
                    />
                  }
                />
                {/* PARKING */}
                <Route
                  path={routes.PARKING_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingSearchResultsPage />}
                    />
                  }
                />
                <Route
                  element={
                    <CurrentProductContextProvider>
                      <Outlet />
                    </CurrentProductContextProvider>
                  }
                >
                  <Route
                    path={routes.PARKING_PDP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledParkingServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.ParkingProductDetailsPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.PARKING_TIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledParkingServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.ParkingTravellerInputPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.PARKING_PIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledParkingServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.ParkingPaymentInputPage />}
                      />
                    }
                  />
                </Route>
                <Route
                  path={routes.PARKING_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingBookingConfirmationPage />}
                    />
                  }
                />
                {/* LOUNGES */}
                <Route
                  path={routes.LOUNGES_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesSearchResultsPage />}
                    />
                  }
                />
                <Route
                  element={
                    <CurrentProductContextProvider>
                      <LoungeProductContextProvider>
                        <Outlet />
                      </LoungeProductContextProvider>
                    </CurrentProductContextProvider>
                  }
                >
                  <Route
                    path={routes.LOUNGES_PDP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledLoungeServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.LoungesProductDetailsPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.LOUNGES_TIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledLoungeServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.LoungesTravellerInputPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.LOUNGES_PIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledLoungeServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.LoungesPaymentInputPage />}
                      />
                    }
                  />
                </Route>
                <Route
                  path={routes.LOUNGES_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesBookingConfirmationPage />}
                    />
                  }
                />
                {/* SWISS TRAVEL */}
                <Route
                  path={routes.SWISS_TRAVEL_PASS_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBSearchInputPage />}
                    />
                  }
                />
                <Route
                  element={
                    <CurrentProductContextProvider>
                      <Outlet />
                    </CurrentProductContextProvider>
                  }
                >
                  <Route
                    path={routes.SWISS_TRAVEL_PASS_TIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledBusServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.SBBTravellerInputPage />}
                      />
                    }
                  />
                  <Route
                    path={routes.SWISS_TRAVEL_PASS_PIP}
                    element={
                      <RedirectPageOnCondition
                        condition={hasEnabledBusServiceProviders}
                        redirectPath={defaultPath}
                        PageElement={<Page.SBBPaymentInputPage />}
                      />
                    }
                  />
                </Route>
                <Route
                  path={routes.SWISS_TRAVEL_PASS_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBBookingConfirmationPage />}
                    />
                  }
                />
                {/* HIGHPASS */}
                <Route
                  path={routes.HIGHPASS_FAST_TRACK}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassFastTrackPage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_LOUNGES}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassLoungesPage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_VIP_SERVICE}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassVipServicePage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_MEET_GREET}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassMeetAndGreetPage />}
                    />
                  }
                />

                {/* BASE ROUTE */}
                <Route
                  index
                  element={
                    <RedirectPageOnCondition
                      condition={isLandingPageEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.LandingPage />}
                    />
                  }
                />
              </Route>
              <Route path={'*'} element={<Navigate to={defaultPath} />} />
            </Routes>
          </LegalModalContextProvider>
        )}
      </GlobalDefaults>
    </BrowserRouter>
  );
};

export default Router;
