/**
 * # Global Defaults
 *
 *
 */
import querystring from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { configureLocale } from 'utils/i18n';

import { useEnterprisePartnerConfigurationContext, useUserProfileContext } from 'context';

const FALLBACK_USER_ID_PREFIX = 'GENERATED_USER_ID-';

export type GlobalConfig = {
  userId: string;
  locale: string;
  tracking: TrackingParam;
  loyaltyProgramId?: string;
  touchpoint?: string;
};

export type TrackingParam = {
  allowed: boolean;
};

export type GlobalConfigKeys = keyof GlobalConfig;

export const supportedLanguagesCodes = ['en', 'de'];

export const getBrowserLocale = (): string => {
  const browserLanguage = window.navigator.language;
  return browserLanguage.split('-')[0];
};

export const defaultGlobalConfig: GlobalConfig = {
  userId: `${FALLBACK_USER_ID_PREFIX}${uuidv4()}`,
  locale: (() => {
    try {
      const browserLocale = getBrowserLocale();
      return supportedLanguagesCodes.includes(browserLocale) ? browserLocale : 'en';
    } catch {
      return 'en';
    }
  })(),
  tracking: {
    allowed: false,
  },
  loyaltyProgramId: undefined,
  touchpoint: undefined,
};

export const globalConfigFields = Object.keys(defaultGlobalConfig) as Array<GlobalConfigKeys>;

export const GlobalDefaults: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { initialiseUserProfile } = useUserProfileContext();
  const {
    loadEnterprisePartnerConfiguration,
    enterprisePartnerConfigurationState: { isEnterprisePartnerConfigurationLoading },
  } = useEnterprisePartnerConfigurationContext();
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [isLoadingRequiredData, setIsLoadingRequiredData] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (isReadyToRender) {
      // indicate application as ready for tests
      setTimeout(() => (document.body.dataset.testid = 'app-ready'));
    }
  }, [isReadyToRender]);

  useEffect(() => {
    // detect finished loading
    if (isLoadingRequiredData && !isEnterprisePartnerConfigurationLoading) {
      setIsLoadingRequiredData(false);
      setIsReadyToRender(true);
    }
  }, [isLoadingRequiredData, isEnterprisePartnerConfigurationLoading]);

  useEffect(() => {
    // detect start loading
    if (isEnterprisePartnerConfigurationLoading) {
      setIsLoadingRequiredData(true);
    }
  }, [isEnterprisePartnerConfigurationLoading]);

  useEffect(() => {
    const queryParams: any = querystring.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    // ensure consistent defaults
    const missingFields = getMissingFields(queryParams);
    if (!missingFields.length && !isInitialised) {
      configureLocale(queryParams.locale);
      loadEnterprisePartnerConfiguration();
      initialiseUserProfile(queryParams.userId);
      setIsInitialised(true);
    }

    const partialDefaults = missingFields.reduce(
      (partialDefaults: Partial<GlobalConfig>, key: string) => {
        const value = defaultGlobalConfig[key as GlobalConfigKeys];
        // @ts-ignore
        partialDefaults[key] = value;
        return partialDefaults;
      },
      {},
    );

    if (queryParams.trackingAllowed && partialDefaults.tracking) {
      partialDefaults.tracking.allowed = queryParams.trackingAllowed === 'true';
    }

    navigate(
      {
        search: querystring.stringify({
          ...queryParams,
          ...partialDefaults,
        }),
      },
      { replace: true },
    );
  }, [
    location.search,
    navigate,
    isInitialised,
    initialiseUserProfile,
    loadEnterprisePartnerConfiguration,
  ]);

  return isReadyToRender ? <>{children}</> : null;
};

/**
 * Get a list of missing fields from a configuration
 *
 * @param config - an object that is supposed to include required global configuration fields
 *
 * @returns List of missing fields in the configuration
 */
function getMissingFields(config: GlobalConfig) {
  const configKeys = Object.keys(config);

  // keys of the necessary fields from the global configuration
  const requiredGlobalConfigFields = globalConfigFields.filter((key: string) => {
    return typeof defaultGlobalConfig[key as GlobalConfigKeys] !== 'undefined';
  });

  return requiredGlobalConfigFields.filter((key: string) => {
    let value = config[key as GlobalConfigKeys];

    // check for supported languages codes
    if (key === 'locale' && !supportedLanguagesCodes.includes(value as string)) {
      value = undefined;
    }

    if (configKeys.includes(key) && value) {
      return false;
    }
    return true;
  });
}

export default GlobalDefaults;
